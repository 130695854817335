/**
    * Lists
    */
ul,
ol {
	list-style: none;
	margin: 0;
	padding: 0;
}

.title * {
	font-size: 73px;
	font-weight: 600;
	line-height: 80px;
	letter-spacing: -1.46px;

	@include media-breakpoint-down(xl) {
		font-size: 48px;
		letter-spacing: -0.72px;
	}
}

h1 {
	@include media-breakpoint-down(xl) {
		font-size: 32px;
		line-height: 40px;
		letter-spacing: -0.32px;
	}
}

h2,
h3 {
	font-weight: 500;
}
