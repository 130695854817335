.modal {
	overflow: hidden;

	&.set-new-pass {
		opacity: 1;
		display: block;

		.modal-content {
			height: 100%;
		}
		.authorization__content {
			& > * {
				display: none;
			}
			.authorization__new-password,
			.new-password__form {
				display: block;
			}
		}
	}
	&-backdrop.show {
		background: rgba(202, 209, 225, 0.9) !important;
	}

	&.active {
		.modal-content {
			height: 100vh;
		}
	}

	&.fade {
		.modal-dialog {
			transform: translateY(100px);
		}
	}

	&.show {
		.modal-dialog {
			transform: translateY(0);
		}
	}

	&-dialog {
		max-width: 100%;
		height: var(--app-height);

		min-height: 100px;
		margin: 0;
		align-items: flex-end;

		@include media-breakpoint-down(md) {
			height: auto;
			min-height: 100vh;
		}
	}
	&-content {
		box-shadow: 0px 0px 64px 0px rgba(0, 77, 229, 0.25);
		border-top: 3px solid $yellow-500;
		border-radius: 0;
		height: calc(var(--app-height) - 152px);
		padding: 51px 20px 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: height 0.5s ease;

		&::-webkit-scrollbar {
			display: none;
		}

		&-inner {
			overflow: auto;
			padding: 0 60px;

			@include media-breakpoint-down(sm) {
				padding: 0;
			}

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}
	&-inner {
		max-width: 554px;
		width: 100%;
		margin: 0 auto;
		height: 100%;

		@include media-breakpoint-down(xl) {
			max-width: 432px;
		}
	}
	&-header {
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		border: none;
		position: relative;
	}
	&-ico {
		font-size: 40px;
		position: absolute;
		top: 0;
		right: calc(100% + 24px);
		color: $yellow-500;

		@include media-breakpoint-down(sm) {
			position: relative;
			right: auto;
			margin-bottom: 12px;
		}
	}
	&-title {
		font-size: 32px;
		font-weight: 500;
		line-height: 40px;
		letter-spacing: -0.32px;
		margin-bottom: 8px;
		    color: #002266;

		@include media-breakpoint-down(sm) {
			font-size: 24px;
			line-height: 32px;
		}
	}
	&-desc {
		font-size: 20px;
		font-weight: 300;
		line-height: 26px;
		letter-spacing: -0.1px;
		margin-bottom: 32px;
	}
	&-body {
		padding: 0;
	}
	&-close {
		position: absolute;
		top: 16px;
		right: 16px;
		font-size: 32px;
		display: flex;

		&:hover {
			.icon {
				color: $blue-900;
			}
		}

		.icon {
			transition: all 0.3s ease-in-out;
		}

		&-btn {
			min-width: 166px;

			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}
	}
}
