@use "sass:math";

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}


.icon-arrow-corner {
  width: (16 / 16) * 1em;
}
.icon-arrow-corner-24 {
  width: (24 / 24) * 1em;
}
.icon-arrow-down {
  width: (16 / 16) * 1em;
}
.icon-arrow-right {
  width: (16 / 16) * 1em;
}
.icon-arrow-right-24 {
  width: (24 / 24) * 1em;
}
.icon-arrow-right-light {
  width: (16 / 16) * 1em;
}
.icon-bullet {
  width: (16 / 16) * 1em;
}
.icon-check {
  width: (16 / 16) * 1em;
}
.icon-cross {
  width: (16 / 16) * 1em;
}
.icon-cross-light {
  width: (16 / 16) * 1em;
}
.icon-download {
  width: (24 / 24) * 1em;
}
.icon-en {
  width: (20 / 20) * 1em;
}
.icon-envelope {
  width: (20 / 20) * 1em;
}
.icon-fb {
  width: (20 / 20) * 1em;
}
.icon-flag {
  width: (24 / 24) * 1em;
}
.icon-globe {
  width: (24 / 24) * 1em;
}
.icon-i {
  width: (24 / 24) * 1em;
}
.icon-info {
  width: (24 / 24) * 1em;
}
.icon-link {
  width: (24 / 24) * 1em;
}
.icon-link-20 {
  width: (20 / 20) * 1em;
}
.icon-linkedin {
  width: (20 / 20) * 1em;
}
.icon-locked {
  width: (24 / 24) * 1em;
}
.icon-mail-20 {
  width: (20 / 20) * 1em;
}
.icon-menu {
  width: (32 / 32) * 1em;
}
.icon-minus {
  width: (24 / 24) * 1em;
}
.icon-plus {
  width: (24 / 24) * 1em;
}
.icon-rebuild {
  width: (48 / 48) * 1em;
}
.icon-shevron-down {
  width: (16 / 16) * 1em;
}
.icon-shevron-down-light {
  width: (16 / 16) * 1em;
}
.icon-shevron-down-light-24 {
  width: (24 / 24) * 1em;
}
.icon-shevron-left {
  width: (32 / 32) * 1em;
}
.icon-shevron-left-64 {
  width: (64 / 64) * 1em;
}
.icon-shevron-right {
  width: (20 / 20) * 1em;
}
.icon-shevron-right-24 {
  width: (24 / 24) * 1em;
}
.icon-shevron-right-32 {
  width: (32 / 32) * 1em;
}
.icon-shevron-right-active-32 {
  width: (32 / 32) * 1em;
}
.icon-shevron-right-light {
  width: (16 / 16) * 1em;
}
.icon-show-passwd {
  width: (20 / 20) * 1em;
}
.icon-tik {
  width: (36 / 36) * 1em;
}
.icon-ukr {
  width: (20 / 20) * 1em;
}
.icon-x {
  width: (21 / 20) * 1em;
}
