button {
	background: none;
	border: 0;
	color: inherit;
	font: inherit;
	line-height: normal;
	overflow: visible;
	padding: 0;
	text-transform: inherit;
	-webkit-appearance: button; /* 1 */
	cursor: pointer; /* 2 */
}

a {
	transition: color 0.3s ease-in-out;
}
