.economic-sectors {
	padding-bottom: 125px;
	position: relative;
	z-index: 2;

	@include media-breakpoint-down(lg) {
		padding-bottom: 96px;
	}

	@include media-breakpoint-down(md) {
		padding-bottom: 64px;
	}

	&__title {
		font-size: 24px;
		font-weight: 500;
		line-height: 32px;
		margin-bottom: 40px;

		@include media-breakpoint-down(xl) {
			margin-bottom: 38px;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 23px;
		}
	}

	&__list {
		display: grid;
		grid-template-columns: repeat(3, 320px);
		column-gap: 20px;
		justify-content: space-between;
		padding-right: 10px;

		@include media-breakpoint-down(xl) {
			grid-template-columns: repeat(2, 416px);
			column-gap: 56px;
			padding-right: 0;
		}

		@include media-breakpoint-down(lg) {
			column-gap: 48px;
			grid-template-columns: repeat(2, 306px);
		}

		@include media-breakpoint-down(md) {
			grid-template-columns: 100%;
			column-gap: 0;
		}
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 16px;
		padding: 20px 8px;
		border-bottom: 1px solid rgba($blue-500, 0.2);
		color: $grey-500;
		transition: background 0.3s ease;

		&:hover {
			background: rgba(0, 128, 255, 0.04);
			color: $grey-500;

			.economic-sectors__item-count {
				display: none;
			}

			.economic-sectors__item-arrow {
				font-size: 24px;
				display: flex;
			}
		}

		&:first-child,
		&:nth-child(2),
		&:nth-child(3) {
			border-top: 1px solid rgba($blue-500, 0.2);
		}

		&:nth-child(3) {
			@include media-breakpoint-down(xl) {
				border-top: 0;
			}
		}

		&:nth-child(2) {
			@include media-breakpoint-down(md) {
				border-top: 0;
			}
		}

		&-title {
			font-size: 16px;
			line-height: 24px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&-arrow {
			display: none;
		}
	}
}
