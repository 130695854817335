@import './main/main';

@import './list-page/list-page';
@import './project-page/project-page';
@import './region-page/region-page';
@import './error/error';

@import './text-page/text-page';
@import './about-page/about-page';

@import './startups-page/startups-page';
@import './personal-page/personal-page';
@import './account-subscription-page/account-subscription-page';
