.startups-banner {
	padding: 128px 0;
	position: relative;
	z-index: 2;

	@include media-breakpoint-down(xl) {
		padding-top: 0;
	}

	@include media-breakpoint-down(md) {
		padding-bottom: 64px;
	}

	@include media-breakpoint-down(md) {
		padding-bottom: 31px;
	}

	.container {
		overflow: visible;
	}

	&__inner {
		display: flex;
		gap: 48px;
		justify-content: space-between;
		align-items: center;

		@include media-breakpoint-down(xl) {
			flex-direction: column-reverse;
			gap: 96px;
		}
		@include media-breakpoint-down(lg) {
			gap: 80px;
		}
		@include media-breakpoint-down(md) {
			gap: 64px;
		}
	}
	&__left {
		display: flex;
		flex-direction: column;
		max-width: 535px;
		width: 100%;
		position: relative;

		@include media-breakpoint-down(xl) {
			max-width: 736px;
			align-items: center;
			text-align: center;
		}

		@include media-breakpoint-down(lg) {
			max-width: 536px;
		}

		@include media-breakpoint-down(md) {
			max-width: 100%;
		}
	}

	&__ua {
		position: absolute;
		top: 128px;
		left: 50%;
		transform: translateX(-50%);

		@include media-breakpoint-down(xl) {
			display: none;
		}
	}
	&__title {
		font-size: 48px;
		font-weight: 600;
		line-height: 48px;
		letter-spacing: -0.72px;
		margin-bottom: 16px;
	}
	&__txt {
		font-size: 20px;
		font-weight: 300;
		line-height: 26px;
		letter-spacing: -0.1px;
		color: $grey-500;
		margin-bottom: 32px;
	}
	&__btn {
		display: flex;

		@include media-breakpoint-down(md) {
			width: 100%;
			flex-grow: 1;
		}

		.button {
			@include media-breakpoint-down(md) {
				width: 100%;
			}
		}
	}

	&__img {
		width: 557px;
		height: 417px;
		border-radius: 16px;
		overflow: hidden;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		object-fit: cover;
		margin: 0 16px;

		img {
			width: 100%;
			/*min-height: 100%;*/
			border-radius: 16px;
		}

		@include media-breakpoint-down(xl) {
			width: calc(100vw - 32px);
			height: 330px;
			border-radius: 0;
		}
		@include media-breakpoint-down(lg) {
			height: 258px;
			width: 100vw;
		}
	}
}
