.advisory-board {
	background-color: $blue-1000;
	padding: 128px 0;
	color: $white;

	&__inner {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__title {
		font-size: 32px;
		font-weight: 500;
		line-height: 40px;
		letter-spacing: -0.32px;
		max-width: 722px;
		text-align: center;
		position: relative;
		color: $white;
		margin: 0;

		&:after {
			content: '';
			margin-top: 32px;
			position: relative;
			height: 4px;
			border-radius: 10px;
			background-color: $yellow-500;
			display: flex;
			max-width: 142px;
			width: 100%;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&__persons {
		display: flex;
		gap: 116px;
		margin: 64px 0;

		@include media-breakpoint-down(md) {
			gap: 48px;
			flex-direction: column;
			align-items: center;
		}
	}

	&-person {
		display: flex;
		flex-direction: column;

		@include media-breakpoint-down(md) {
			align-items: center;
		}

		&__img {
			width: 297px;
			height: 297px;
			border-radius: 16px;
			margin-bottom: 16px;
			object-fit: cover;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $blue-1000;

			@include media-breakpoint-down(xl) {
				height: 192px;
				width: 192px;
			}

			img,
			picture {
				border-radius: 16px;
				overflow: hidden;
				background-color: $blue-1000;
			}
		}

		&__info {
			display: flex;
			flex-direction: column;
			text-align: center;
		}

		&__name {
			font-size: 24px;
			font-weight: 500;
			line-height: 32px;
		}

		&__role {
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			color: rgba($color: #fff, $alpha: 0.5);
			margin-top: 8px;

			@include media-breakpoint-down(md) {
				max-width: 192px;
				text-align: center;
			}
		}

		&__links {
			margin-top: 17px;
			display: flex;
			align-items: center;
			gap: 12px;
			justify-content: center;
		}
		&__link {
			font-size: 20px;
			color: $white;
		}
	}

	&__coa {
		display: flex;

		.icon {
			font-size: 48px;
			color: #ffd500;
		}
	}
}
