.breadcrumbs {
	display: flex;
	align-items: center;
	gap: 8px;
	flex-wrap: wrap;

	&__item {
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		color: $blue-500;
		transition: color 0.2s ease-in-out;

		&:is(a) {
			&:hover {
				color: $blue-600;
			}
		}
	}
	&__separator {
		color: $grey-500;
		display: flex;
	}
}
