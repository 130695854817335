// Global styles

:root {
	--app-height: 100%; /* This will be updated dynamically by JavaScript */
}

html,
body {
	height: 100vh;
	height: var(--app-height);
}

body {
	position: relative;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		background: rgba(202, 209, 225, 0.9);
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s ease;
		z-index: 3;
	}

	&.lock {
		@include media-breakpoint-down(lg) {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		&:after {
			opacity: 1;
			pointer-events: inherit;
		}
	}
}

.l-page {
	flex-grow: 1;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	justify-content: space-between;
	overflow-x: hidden;
	background-color: $white;
}

.l-main {
	flex-grow: 1;
	margin: 0 16px 16px;
	background-color: $blue-100;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(lg) {
		margin: 0;
		border-radius: 0;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background-image: url(../images/lead-bg.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		min-height: 683px;
		pointer-events: none;
		z-index: 0;
	}
}

.content {
	position: relative;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

// .container {
// 	@include media-breakpoint-down(xs) {
// 		padding: 0 16px;
// 	}
// }

.target-anchor {
	scroll-margin-top: 48px;
}

.g-recaptcha {
	opacity: 0;
}
