.startups {
	padding: 117px 0 96px;

	@include media-breakpoint-down(lg) {
		padding-top: 127px;
	}

	@include media-breakpoint-down(md) {
		padding: 64px 0;
	}
	&__title {
		font-size: 32px;
		font-weight: 500;
		line-height: 40px;
		letter-spacing: -0.32px;
		max-width: 722px;
		text-align: center;
		position: relative;
		color: $blue-900;

		&:after {
			content: '';
			margin-top: 32px;
			position: relative;
			height: 4px;
			border-radius: 10px;
			background-color: $yellow-500;
			display: flex;
			max-width: 142px;
			width: 100%;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	&__inner {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__main {
		margin-top: 40px;
	}
	&__filters {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 8px;

		input {
			opacity: 0;
			height: 0;
			width: 0;
			&:checked + span {
				background-color: $yellow-500;
				color: $blue-900;
			}
		}

		span {
			display: inline-flex;
			background-color: rgba(0, 128, 255, 0.08);
			border-radius: 100px;
			padding: 2px 12px 4px 12px;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			color: $grey-500;
			cursor: pointer;
			transition: all 0.3s ease;

			&:hover {
				color: $blue-900;
			}
		}
	}
	&__table {
		margin-top: 40px;
		border-top: 1px solid rgba($blue-500, 0.2);
		display: flex;
		flex-direction: column;
		align-items: center;
		&-inner {
			width: 100%;
		}
	}
	&-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 40px 12px;
		border-bottom: 1px solid rgba($blue-500, 0.2);
		width: 100%;
		cursor: pointer;

		@include media-breakpoint-down(md) {
			padding: 24px 0;
			padding-left: 4px;
		}

		&:hover {
			.startups-item__ico svg {
				transform: translateX(3px);
			}
			.startups-item__img {
				border: 1px solid rgba($blue-900, 0.6);
			}
		}

		&__img {
			height: 48px;
			width: 48px;
			border: 1px solid rgba(0, 128, 255, 0.2);
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			margin-right: 8px;
			object-fit: cover;
			transition: all 0.3s ease;

			@include media-breakpoint-down(md) {
				height: 40px;
				width: 40px;
			}

			img {
				height: 100%;
				width: auto;
			}
		}

		&__info {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		&__name {
			font-size: 24px;
			font-weight: 400;
			line-height: 32px;
			letter-spacing: -0.24px;
			color: $blue-900;
			transition: all 0.3s ease;

			@include media-breakpoint-down(md) {
				font-size: 20px;
				line-height: normal;
			}
		}

		&__category {
			@include media-breakpoint-down(md) {
				display: none;
			}
		}

		&__link {
			color: $blue-500;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;

			@include media-breakpoint-down(md) {
				font-size: 14px;
				line-height: normal;
			}
		}

		&__left {
			display: flex;
			align-items: center;
		}
		&__right {
			display: flex;
			align-items: center;
		}
		&__ico {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 32px;
			width: 32px;
			border-radius: 50%;
			background-color: $yellow-500;
			margin-left: 16px;

			@include media-breakpoint-down(md) {
				height: auto;
				width: auto;
				background-color: transparent;
			}

			svg {
				transition: all 0.3s ease;
			}
		}
	}
	&__btn {
		margin-top: 40px;
	}
}
