.list {
	&__info {
		max-width: 725px;

		@include media-breakpoint-down(xl) {
			max-width: 100%;
		}
	}

	&__form {
		display: flex;
		.select {
			margin-left: 0.5rem;

			.ts-dropdown {
				@include media-breakpoint-down(xl) {
					left: 0;
					right: auto;
				}
			}

			@include media-breakpoint-down(xl) {
				margin-left: 0;
			}
		}
	}

	&__top-line {
		display: flex;
		position: relative;
		align-items: center;
		margin-bottom: 15px;
	}

	&__head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 40px;

		@include media-breakpoint-down(xl) {
			margin-top: 41px;
		}

		@include media-breakpoint-down(lg) {
			margin-top: 49px;
			margin-bottom: 24px;
			align-items: flex-end;
		}

		@include media-breakpoint-down(md) {
			margin-top: 24px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 24px;
		}
	}

	&__small-title {
		font-size: 32px;
		font-weight: 500;
		line-height: 40px;
		letter-spacing: -0.32px;
		margin: 0;
		margin-right: 20px;
	}

	&__back {
		font-size: 64px;
		color: $grey-500;
		transition: color 0.2s ease-in-out;
		display: flex;
		align-items: center;
		position: absolute;
		right: calc(100% + 21px);

		@media screen and (max-width: 1350px) {
			display: none;
		}

		&:hover {
			color: $blue-900;
		}
	}

	&__ua {
		width: 80px;
		height: 80px;
		margin-left: 25px;

		@include media-breakpoint-down(lg) {
			width: 48px;
			height: 48px;
			margin-left: 22px;
		}

		@include media-breakpoint-down(md) {
			width: 40px;
			height: 40px;
			margin-left: 8px;
			margin-right: -14px;
		}
	}

	&__title {
		font-size: 73px;
		font-weight: 600;
		line-height: 80px;
		letter-spacing: -1.46px;
		margin: 0;

		@include media-breakpoint-down(lg) {
			font-size: 48px;
			line-height: 48px;
			letter-spacing: -0.72px;
		}

		@include media-breakpoint-down(md) {
			font-size: 32px;
			font-weight: 500;
			line-height: 40px;
			letter-spacing: -0.32px;
		}
	}

	&__desc {
		font-size: 24px;
		font-weight: 300;
		line-height: 32px;
		letter-spacing: -0.24px;
		margin-top: 24px;

		b {
			color: $blue-900;
			font-weight: 300;
		}

		@include media-breakpoint-down(lg) {
			font-size: 20px;
			font-weight: 300;
			line-height: 26px;
			letter-spacing: -0.1px;
		}
	}

	&__send-inq {
		margin-top: 32px;
	}

	&__load-more {
		margin-top: 40px;
		display: flex;
		justify-content: center;

		button {
			min-width: 364px;

			&.disabled {
				opacity: 0.5;
				pointer-events: none;
			}

			@include media-breakpoint-down(lg) {
				min-width: 280px;
				padding: 15px 32px;
			}
		}
	}

	&__btn {
		width: 364px;
	}

	&__empty {
		height: min-content;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 40px 0;
		border-top: 1px solid rgba($blue-500, 0.2);
		border-bottom: 1px solid rgba($blue-500, 0.2);

		&-txt {
			font-size: 20px;
			font-style: normal;
			font-weight: 300;
			line-height: 26px;
			letter-spacing: -0.1px;
			color: $grey-400;

			a {
				color: $blue-500;

				&:hover {
					color: $blue-900;
				}
			}
		}

		&-btn {
			display: inline;
			color: $blue-500;
			font-weight: 300;
			padding: 0;
			font-size: 20px;

			&:hover {
				background-color: transparent;
			}
		}
	}

	&__sort {
		display: flex;
		margin-left: auto;
		justify-content: flex-end;
		flex-shrink: 0;

		@include media-breakpoint-down(xl) {
			margin-left: 0;
		}
	}
	&__pagination,
	.wp-pagenavi {
		display: flex;
		justify-content: center;
		margin-top: 40px;
		gap: 20px;
		&-pages {
			display: flex;
			align-items: center;
			gap: 20px;
		}
		.pages {
			display: none;
		}
		&-btn,
		.last,
		.first,
		.nextpostslink,
		.previouspostslink {
			height: 30px;
			width: 30px;
			border-radius: 50%;
			/*border: 1px solid $grey-300;*/
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.2s ease-in-out;
			&:hover {
				background-color: $blue-500;
				border-color: $blue-500;
				color: #fff;
			}
			&.disable,
			.current {
				opacity: 0.5;
				pointer-events: none;
			}
			&--prev {
				.icon {
					transform: rotate(180deg);
				}
			}
		}
		&-link,
		.page {
			color: $blue-500;
			font-size: 16px;
			font-weight: 300;
			line-height: 26px;
			letter-spacing: -0.1px;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
			&.active {
				color: $blue-900;
			}
		}
	}
}
.wp-pagenavi {
	display: flex;
	align-items: center;
	gap: 20px;
}
