.home-info {
	padding: 80px 0 0;

	@include media-breakpoint-down(md) {
		padding: 48px 0 0;
	}

	&__inner {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		gap: 48px;
		position: relative;
		z-index: 2;

		@include media-breakpoint-down(xl) {
			align-items: flex-start;
		}

		@include media-breakpoint-down(lg) {
			flex-direction: column;
			justify-content: flex-start;
		}
	}

	&__left {
		max-width: 728px;
		width: 100%;

		@include media-breakpoint-down(lg) {
			max-width: 100%;
		}
	}
	&__title {
		font-size: 73px;
		font-weight: 600;
		line-height: 80px;
		letter-spacing: -1.46px;
		margin-bottom: 16px;

		@include media-breakpoint-down(xl) {
			letter-spacing: -1px;
		}

		@include media-breakpoint-down(lg) {
			font-size: 48px;
			line-height: 48px;
			letter-spacing: -0.72px;
		}

		br {
			display: none;

			@include media-breakpoint-down(md) {
				display: block;
			}
		}
	}
	&__desc {
		font-size: 24px;
		font-style: normal;
		font-weight: 300;
		line-height: 32px;
		letter-spacing: -0.24px;
		margin-bottom: 32px;

		@include media-breakpoint-down(md) {
			font-size: 20px;
			font-weight: 300;
			line-height: 26px;
			letter-spacing: -0.1px;
		}

		@include media-breakpoint-down(xl) {
			max-width: 518px;
		}

		@include media-breakpoint-down(lg) {
			max-width: 540px;
		}
	}
	&__btns {
		display: flex;
		align-items: center;
		gap: 16px;

		@include media-breakpoint-down(md) {
			flex-direction: column;
			align-items: center;
		}

		.button {
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}
	}

	&__right {
		max-width: 364px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		padding-bottom: 15px;

		@include media-breakpoint-down(xl) {
			max-width: 250px;
			padding-bottom: 0;
			padding-top: 97px;
		}

		@include media-breakpoint-down(lg) {
			display: none;
		}

		&--mobile {
			display: none;

			@include media-breakpoint-down(lg) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				flex-direction: row;
				max-width: 100%;
				gap: 32px;
				padding: 24px 0;
				border-top: 1px solid rgba($blue-500, 0.2);
				border-bottom: 1px solid rgba($blue-500, 0.2);
				margin-bottom: 32px;
			}

			@include media-breakpoint-down(md) {
				grid-template-columns: 1fr;
				gap: 25px;
			}
		}
	}

	&-stat {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		position: relative;

		@include media-breakpoint-down(lg) {
			align-items: flex-start;
		}

		&:not(:first-child) {
			padding-top: 33px;

			@include media-breakpoint-down(lg) {
				padding: 0;
			}

			@include media-breakpoint-down(md) {
				border-top: 1px solid rgba($blue-500, 0.2);
				padding-top: 24px;
			}
		}
		&:not(:last-child) {
			padding-bottom: 32px;

			@include media-breakpoint-down(lg) {
				padding: 0;
			}

			&:after {
				content: '';
				background: linear-gradient(270deg, rgba(0, 128, 255, 0.2) 0%, rgba(0, 128, 255, 0) 100%);
				height: 1px;
				width: 100%;
				position: absolute;
				right: 0;
				bottom: 0;
				left: 0;

				@include media-breakpoint-down(lg) {
					display: none;
				}
			}
		}
		&__ttl {
			font-size: 48px;
			font-weight: 600;
			line-height: 48px;
			letter-spacing: -0.72px;
			color: $blue-900;
			margin-bottom: 3px;
			transition: all 0.2s ease-in-out;
			display: flex;

			@include media-breakpoint-down(xl) {
				font-size: 32px;
				font-weight: 500;
				line-height: 40px;
				letter-spacing: -0.32px;
				margin-bottom: 0;
			}
		}
		&__val {
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
		}
	}
}
