.list-page {
	padding: 44px 0 4px;

	@include media-breakpoint-down(md) {
		padding: 28px 0 4px;
	}

	&__info {
		max-width: 725px;
	}

	&__breadcrumbs {
		margin-bottom: 13px;
	}

	&__content {
		padding: 0 0 96px;

		@include media-breakpoint-down(xl) {
			padding: 0 0 61px;
		}
	}
}
