.success-val {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 214px;
  margin-bottom: 247px;

  @include media-breakpoint-down(md) {
    margin-top: 107px;
    margin-bottom: 123px;
  }
  gap: 32px;

  &__tik {
    margin: auto;
    display: flex;
    align-items: center;
    width: 46px;
    height: 46px;
    border-radius: 287.5px;
    background: $green;
  }

  &__icon {
    width: 34.5px;
    height: 34.5px;
    margin: auto;
    flex-shrink: 0;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 12px;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: $blue-900;
  }

  &__description {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: $grey-500;
  }
}