.footer {
	background-color: $grey-50;
	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__top {
		padding: 30px 16px 24px;
		border-top: 1px solid $grey-100;
		border-bottom: 1px solid $grey-100;
	}

	&__bottom {
		padding: 4px 16px 44px;

		@include media-breakpoint-down(md) {
			padding: 12px 16px 6px;
		}
	}

	&__ua {
		font-size: 40px;
		margin-right: 94px;
		display: flex;
		color: $grey-500;

		@include media-breakpoint-down(xl) {
			margin-right: 24px;
		}
	}

	&__left {
		display: flex;
		align-items: center;
	}

	&__submit {
		color: $blue-900;
		margin-right: 66px;

		@include media-breakpoint-down(xl) {
			margin-right: 28px;
		}

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	&__right {
		display: flex;
		align-items: center;
	}

	&__mail {
		color: $blue-500;
		margin-right: 32px;

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	&-menu {
		@include media-breakpoint-down(lg) {
			display: none;
		}
		&__list {
			display: flex;
			gap: 34px;

			@include media-breakpoint-down(xl) {
				gap: 24px;
			}
		}

		&__link {
			color: $grey-500;
			font-weight: 400;

			&:hover , .current-menu-item &{
				color: $blue-900;
			}
		}
	}
	&__txt {
		color: $grey-300;
		font-size: 14px;
		line-height: 22px;
	}
}
