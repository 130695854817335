.profile-dropdown-link {
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	color: $blue-900 !important;
	white-space: nowrap;
	/*display: none;*/

	&:hover {
		color: $blue-900;
	}
}

.profile-dropdown {
	display: flex;
	.dropdown-menu {
		gap: 16px;
		padding: 16px;
		border-radius: 12px;
		background: $white;
		box-shadow: 0px 16px 48px 0px rgba(0, 77, 229, 0.16);
		align-items: flex-start;
		max-width: 364px;
		width: 364px;
	}

	.profile-logo {
		width: 36px;
		height: 36px;
		background: url('../images/user.png');
		border-radius: 36px;
		overflow: hidden;

		@media (max-width: 959px) {
			display: none;
		}
	}

	.profile-avatar {
		width: 56px;
		height: 56px;
		flex-shrink: 0;
		border-radius: 36px;
		background: url('../images/user.png');
		background-size: cover;
	}

	.info {
		display: flex;
		text-align: start;
		padding-top: 5px;
		padding-bottom: 21px;
		flex-direction: column;
	}

	.title {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: $blue-900;
	}

	.email {
		font-size: 14px;
		font-weight: 300;
		line-height: 22px;
		letter-spacing: 0.14px;
		color: $grey-500;
	}

	.profile-btn {
		display: flex;
		text-align: start;
		padding-top: 16px;
		padding-bottom: 16px;
		border-top: 1px solid $grey-500;
	}

	.btn {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: $grey-500;
		padding-left: 0;

		&:hover {
			color: $blue-900;
		}
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.show {
		inset: 20px -155px auto auto !important;
		display: flex;
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}

.profile-card {
	display: none !important;
	border: 1px solid $grey-500;
	flex-direction: row;
	height: auto;
	width: 100%;
	margin-top: 50px;
	gap: 24px;
	padding: 16px;
	border-radius: 12px;
	background: $white;
	align-items: flex-start;
	width: 100%;

	@include media-breakpoint-down(md) {
		display: flex !important;
	}
}

.mobile-info {
	display: flex;
	text-align: start;
	flex-direction: column;
}

.mobile-btn {
	display: flex;
	text-align: start;
	border-top: 1px solid $grey-500;
	padding-top: 8px;

	&-btn {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: $grey-500;
		padding-left: 0;
	}
}

.mobile-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 16px;
}

.mobile-avatar {
	display: flex;
	width: 48px;
	height: 48px;
	flex-shrink: 0;
	border-radius: 36px;
	background: linear-gradient(180deg, #dbecff 0%, #e6f2ff 81.25%, #e6f2ff 100%);
}

.profile-dropdown-link {
	&.show {
		display: block;
	}
	&.hide {
		display: none;
	}
}
