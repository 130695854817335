.talents {
	padding: 96px 0;
	position: relative;
	z-index: 2;

	@include media-breakpoint-down(lg) {
		padding-bottom: 128px;
	}

	&__inner {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__list {
		border: 1px solid rgba(0, 128, 255, 0.2);
		border-radius: 16px;
		display: flex;
		flex-wrap: wrap;
		margin-top: 48px;
	}

	&-item {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 24px 32px;
		width: 25%;
		min-height: 192px;

		@include media-breakpoint-down(lg) {
			min-height: 218px;
		}

		@include media-breakpoint-down(md) {
			width: 50%;
			border-bottom: 1px solid rgba(0, 128, 255, 0.2);
			padding: 20px;
			min-height: 192px;
		}

		&:not(:last-child) {
			border-right: 1px solid rgba(0, 128, 255, 0.2);

			@include media-breakpoint-down(md) {
				border-right: 0;
			}
		}

		&:nth-child(3),
		&:nth-child(5) {
			@include media-breakpoint-down(md) {
				border-right: 1px solid rgba(0, 128, 255, 0.2);
			}
		}
		&:nth-last-child(-n + 2) {
			@include media-breakpoint-down(md) {
				border-bottom: 0;
			}
		}

		&:first-child,
		&:nth-child(2) {
			width: 50%;
			border-bottom: 1px solid rgba(0, 128, 255, 0.2);
			min-height: 224px;

			@include media-breakpoint-down(lg) {
				min-height: 191px;
			}

			@include media-breakpoint-down(md) {
				width: 100%;
				min-height: 192px;
			}
			.talents-item__number {
				font-size: 48px;
				line-height: 48px;
				letter-spacing: -0.72px;
			}
		}

		&.number-one {
			.talents-item__number {
				position: relative;
				border-radius: 20px;
				background: $white;
				padding: 0 12px;
				&:before {
					content: '#';
					color: $yellow-550;
					font-size: 32px;
					font-weight: 500;
					line-height: 40px;
					letter-spacing: -0.32px;
				}
			}
		}

		&__number {
			font-size: 32px;
			font-weight: 500;
			line-height: 40px;
			letter-spacing: -0.32px;
			color: $blue-900;
		}

		&__head {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		&__footer {
			display: flex;
			flex-wrap: wrap;
			font-size: 14px;
			line-height: normal;
			span {
				font-weight: 300;
				color: $grey-500;
				margin-right: 4px;

				@include media-breakpoint-down(lg) {
					line-height: 22px;
				}
			}
			b {
				color: $blue-900;
				font-weight: 500;

				@include media-breakpoint-down(lg) {
					line-height: 22px;
				}
			}
		}
	}

	&__title {
		font-size: 32px;
		font-weight: 500;
		line-height: 40px;
		letter-spacing: -0.32px;
		max-width: 722px;
		text-align: center;
		position: relative;
		color: $blue-900;
		margin: 0;

		&:after {
			content: '';
			margin-top: 32px;
			position: relative;
			height: 4px;
			border-radius: 10px;
			background-color: $yellow-500;
			display: flex;
			max-width: 142px;
			width: 100%;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}
