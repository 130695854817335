.authorization {
	&__sign-up {
		display: none;
	}
	&__welcome {
		display: none;
	}
	&__verify-email {
		display: none;
	}
	&__password {
		display: none;
	}
}

.modal__authorization {
	.modal-inner {
		width: 364px;
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}
}

.sign-up,
.login,
.password,
.new-password {
	&__form {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__row {
		width: 100%;
	}
	&__login {
		color: $blue-900;
		cursor: pointer;
	}
	&__terms {
		text-align: center;
		a:hover {
			color: $blue-900;
		}
	}
	&__pass-recovery {
		align-self: flex-end;
		color: $blue-900;
		cursor: pointer;
	}
	&__sign-up {
		color: $blue-900;
		cursor: pointer;
	}
	&__btn {
		width: 100%;
		margin-top: 12px;
		margin-bottom: 24px;
		button.password__submit {
			width: auto;
			margin-left: auto;
		}
		.button {
			width: 100%;
		}
	}
}

.welcome,
.verify-email {
	&__block {
		display: flex;
		flex-direction: column;
	}
	&__row {
		display: flex;
		justify-content: space-between;
		gap: 20px;
		@include media-breakpoint-down(lg) {
			flex-wrap: wrap;
		}
	}
	&__item {
		width: 100%;
		&--tel {
			width: 100%;
		}
	}
	&__form {
		display: flex;
		flex-direction: column;
	}
	&__btn {
		align-self: flex-end;
		margin-top: 20px;
	}
}

.password__btn.logIn-hidden {
	display: none;
	width: auto;
	margin-top: 0px;
	button {
		margin-left: 0;
	}
}
.emphasize-blue {
	color: $blue-900;
}
.new-password__form {
	display: none;
	flex-direction: column;
	gap: 10px;
}
.return-to-account {
	display: none;
}
