.startup-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 96px 0;

  &__txt {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -0.72px;
    color: $blue-900;
    text-align: center;
    margin-bottom: 16px;
  }

  &__subtitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 26px;
    color: $blue-900;
    text-align: center;
    letter-spacing: -0.1px;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
    }
  }

  &__sliders {
    padding: 48px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__slider {
    width: 100%;
    overflow: visible;

    .swiper-wrapper {
      transition-timing-function: linear;
    }

    .swiper-slide {
      width: auto;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    box-shadow: 0px 2px 20px 0px rgba(0, 77, 229, 0.12);
    border-radius: 16px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    will-change: auto;
    min-width: 280px;

    @include media-breakpoint-down(sm) {
      padding: 10px 16px;
      min-width: auto;
    }

    &:hover {
      box-shadow: 0px 16px 48px 0px rgba(0, 77, 229, 0.16);
    }

    &__img {
      border: 1px solid rgba(0, 34, 102, 0.1);
      height: 40px;
      width: 40px;
      margin-right: 8px;
      border-radius: 50%;

      @include media-breakpoint-down(sm) {
        height: 32px;
        width: 32px;
      }
    }

    &__info {
      margin-right: 11px;
      display: flex;
      flex-direction: column;
    }

    &__title {
      font-size: 20px;
      font-style: normal;
      line-height: 26px;
      letter-spacing: -0.24px;
      color: $blue-900;
      margin: 0;

      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }

    &__descr {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: $grey-400;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &__btn {
      margin-left: auto;
      .icon {
        font-size: 20px;
        color: #6b83b3;
      }
    }
  }
}

