.personal-page {
	&__inner {
		display: flex;
		gap: 124px;
		justify-content: center;
		@include media-breakpoint-down(xl) {
			gap: 24px;
		}
		@include media-breakpoint-down(md) {
			gap: 0;
      display: block;
		}
	}
	&__title {
		height: 40px;
		margin: 28px 0;
    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }
	}
	&__right {
		width: 750px;
		@include media-breakpoint-down(xl) {
			width: 584px;
		}
		@include media-breakpoint-down(lg) {
			width: 664px;
		}
		@include media-breakpoint-down(md) {
			width: auto;
		}
	}
	&__left {
		@include media-breakpoint-down(md) {
      display: none;
    }
	}

  &__details {
    display: none;
    @include media-breakpoint-down(md) {
			display: block;
      margin-bottom: 20px;
      overflow-x: auto;
		}
  }
  .container {
    @include media-breakpoint-down(xl) {
      padding: 15px;
      max-width: 900px;
		}
  }
}
