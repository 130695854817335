.submit-project {
	&__form {
		gap: 10px;
		display: flex;
		flex-direction: column;

		.form-control__error {
			top: calc(100%);
		}
	}

	&__btn {
		display: flex;
		justify-content: flex-end;
		margin-top: 6px;

		@include media-breakpoint-down(sm) {
			margin-top: 20px;
		}

		.button {
			min-width: 166px;

			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}
	}
}
