.map-tooltip {
  position: absolute;
  display: flex;
  min-width: 200px;
  min-height: 62px;
  padding: 8px 16px 12px 80px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 16px 48px 0px rgba(0, 77, 229, 0.3);

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  color: #026;
  pointer-events: none;

	@include media-breakpoint-down(md) {
		display: none !important;
	}

  &__coat {
    position: absolute;
    left: 8px;
    top: -13px;
    flex-shrink: 0;
  }

  &__caption {
  }

  &__price {

  }

  &__description {
    color: $grey-500;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}


.map {
	display: flex;
	align-items: center;
	justify-content: center;

	@include media-breakpoint-down(xl) {
		margin: 0 0 10px;
	}

	@include media-breakpoint-down(lg) {
		margin: 55px 0 -3px;
	}

	@include media-breakpoint-down(md) {
		margin: 55px 0 20px;
	}

  &__container {
    position: relative;

		@include media-breakpoint-down(md) {
      padding: 0;
			max-width: none;
		}
  }

	&__inner {
		display: flex;
		justify-content: center;
	}

	&__trezub {
		position: absolute;
		right: -15%;
		top: 50%;
		transform: translateY(-50%);

		@include media-breakpoint-down(xl) {
			right: 0;
		}

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	&__img {
		position: relative;
		z-index: 2;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		@include media-breakpoint-down(md) {
			background-color: var(--blue-5008, rgba(0, 128, 255, 0.08));
		}
	}

  &__twoFingers {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
    color: $blue-900;
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: -0.24px;
    background-color: rgba(212, 233, 255, 0.9);
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    z-index: 9;

    &.isActive {
      opacity: 1;
    }

		@include media-breakpoint-up(md) {
			display: none;
		}

    .map-popup-is-opened & {
      display: none;
    }

		@include media-breakpoint-up(md) {
			display: none;
		}
  }

	&__zoom {
    position: absolute;
    left: 20px;
    bottom: 30px;
    width: 40px;
    height: 80px;
    border-radius: 40px;
    background: $white;
    box-shadow: 0px 2px 20px 0px rgba(0, 77, 229, 0.12);
    z-index: 9;

		@include media-breakpoint-up(md) {
			display: none;
		}

    &:after{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 4px;
      right: 4px;
      margin: auto;
      width: 32px;
      height: 1px;
      flex-shrink: 0;
      opacity: 0.1;
      background: $blue-500;
    }

    &__ctrl {
      display: flex;
      font-size: 24px;
      padding: 8px;
      background-color: transparent;
      color: $grey-500;

      &:active {
        transform: scale(1.1);
      }
    }
	}

  &__svg {
    display: block;
    filter: drop-shadow(3px 3px 5px #A6C4E2);
    max-width: 100%;
    height: auto;

    .label {
      filter: drop-shadow(3px 3px 5px rgba(0, 77, 229, 0.1));
    }

		@include media-breakpoint-down(md) {
			height: 480px;
      width: 100% !important;

      transition: height 300ms ease;

      .map-popup-is-opened & {
        height: calc(100dvh - 100px);
      }
		}
  }

  &__popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    transform: translateY(100%);
    opacity: 0;

    transition-property: transform, opacity;
    transition-duration: 500ms;

    .map-popup-is-opened & {
      transform: translateY(0%);
      opacity: 1;
    }

    &__container {
      @include media-breakpoint-down(md) {
        padding: 0;
        max-width: none;
      }
    }

    &__inner {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      padding: 12px 20px;
      gap: 8px;
      border-radius: 16px 16px 0px 0px;
      background-color: $white;
      box-shadow: 0px 16px 48px 0px rgba(0, 77, 229, 0.16);
      color: $blue-900;

      &:before {
        content: '';
        position: absolute;
        top: -12px;
        left: 0;
        right: 0;
        margin: auto;
        width: 80px;
        height: 6px;
        border-radius: 10px;
        background: rgba(0, 128, 255, 0.1);
      }
    }

    &__title {
      font-family: Geologica;
      font-size: 24px;
      line-height: 32px; /* 133.333% */
      letter-spacing: -0.24px;
    }

    &__coat {
      position: absolute;
      right: 20px;
      top: -24px;
    }

    &__body {
      display: flex;
      gap: 16px;
    }

    &__caption {
      flex-grow: 1;
    }

    &__price {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    &__description {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      line-height: 18px;
      color: $grey-500;
    }

    &__btn {
      flex-shrink: 0;
    }
  }

  &__back-btn {
    position: fixed;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0px 2px 20px 0px rgba(0, 77, 229, 0.12);
    font-size: 24px;
    z-index: 10;

    transform: translateX(-100%);
    opacity: 0;

    transition-property: transform, opacity;
    transition-duration: 500ms;

    .map-popup-is-opened & {
      transform: translateX(0%);
      opacity: 1;
    }
  }

}

.map-popup-is-opened {
  overflow: hidden;
}

.coats-of-arms-sprite {
  background: url('../assets/coats-of-arms.sprite.png') no-repeat top left;
  width: 64px;
  height: 64px;
  
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background: url('../assets/coats-of-arms.retina.sprite.png') no-repeat top left;
      background-size: 66px 1825px;
  }
  
  &.Kyiv {
      background-position: 0 0;
      height: 65px;
  }
  &.Sevastopol {
      background-position: 0 -66px;
  }
  &.Crimea {
      background-position: 0 -131px;
  }
  &.Zhytomyr {
      background-position: 0 -196px;
      height: 65px;
  }
  &.Zaporizhzhia {
      background-position: 0 -262px;
  }
  &.Zakarpattia {
      background-position: 0 -327px;
      height: 65px;
  }
  &.Volyn {
      background-position: 0 -393px;
  }
  &.Vinnytsia {
      background-position: 0 -458px;
  }
  &.Ternopil {
      background-position: 0 -523px;
  }
  &.Sumy {
      background-position: 0 -588px;
  }
  &.Rivne {
      background-position: 0 -653px;
  }
  &.Poltava {
      background-position: 0 -718px;
  }
  &.Odesa {
      background-position: 0 -783px;
      width: 66px;
  }
  &.Mykolaiv {
      background-position: 0 -848px;
  }
  &.Lviv {
      background-position: 0 -913px;
  }
  &.Luhansk {
      background-position: 0 -978px;
  }
  &.Kirovohrad {
      background-position: 0 -1043px;
  }
  &.Khmelnytskyi {
      background-position: 0 -1108px;
  }
  &.Kherson {
      background-position: 0 -1173px;
  }
  &.Kharkiv {
      background-position: 0 -1238px;
  }
  &.Ivano-Frankivsk {
      background-position: 0 -1303px;
  }
  &.Donetsk {
      background-position: 0 -1368px;
      height: 65px;
  }
  &.Dnipropetrovsk {
      background-position: 0 -1434px;
      height: 65px;
  }
  &.Chernivtsi {
      background-position: 0 -1500px;
  }
  &.Chernihiv {
      background-position: 0 -1565px;
  }
  &.Cherkasy {
      background-position: 0 -1630px;
  }
  &.Ukraine {
      background-position: 0 -1695px;
  }
  &.Kyiv-City {
      background-position: 0 -1760px;
  }
}
