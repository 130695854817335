.account-main {
	&__banner {
		height: 288px;
		border-radius: 16px;
		background-color: blue;
		background: url(../images/account-banner.png);
		background-size: cover;
		background-position: center;
		overflow: hidden;
		@include media-breakpoint-down(sm) {
			height: 526px;
		}
	}
	&__wrapper {
		display: flex;
		width: 353px;
		height: 100%;
		@include media-breakpoint-down(sm) {
			flex-direction: column-reverse;
			width: 100%;
		}
	}
	&__content {
		padding: 20px;
		@include media-breakpoint-down(sm) {
			padding: 0 10px;
			display: flex;
			flex-direction: column;
			margin-bottom: 30px;
		}
	}

	&__button {
		margin: 0 auto;
	}
	&__text {
		margin-bottom: 24px;
		width: 353px;
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
		&--title {
			font-size: 20px;
			font-weight: 400;
			color: #fff;
			@include media-breakpoint-down(sm) {
				text-align: center;
			}
			p {
				margin-bottom: 0;
			}
		}
		&--additional {
			display: flex;
			flex-direction: column;
			gap: 12px;
			margin-top: 12px;

			@include media-breakpoint-down(sm) {
				align-items: center;
			}

			span {
				color: #fff;
			}
			p {
				margin: 0;
			}
			font-size: 16px;
			font-weight: 400;
			color: rgba(255, 255, 255, 0.67);
		}
		&--item {
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}
	&__info {
		margin-top: 10px;
		&--basic {
			display: flex;
			flex-direction: column;
			margin-top: 36px;
		}
		&--personal {
			margin-top: 48px;
			margin-bottom: 98px;
		}
		&--item {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			height: 56px;
			h3 {
				margin: 0;
			}
			p {
				margin: 0;
			}
			span {
				color: $gray-800;
			}
			button {
				color: $blue-500;
			}
		}
		&--line {
			width: 100%;
			height: 5px;
			color: green;
		}
	}
	&__trezub--mobile {
		display: none;
		@include media-breakpoint-down(sm) {
			background: url(../../images/yellow-trezub.svg);
			height: 50%;
			width: auto;
			background-position: center;
			display: block;
			padding-top: 10px;
			background-repeat: no-repeat;
			height: 50%;
			width: auto;
			background-position: center;
		}
	}
	&__trezub--desktop {
		display: block;
		position: relative;
		img {
			position: absolute;
			width: 350px;
			height: auto;
			top: -106px;
			max-width: none;
			left: 100px;
			@include media-breakpoint-down(xl) {
				left: -70px;
			}
			@include media-breakpoint-down(md) {
				left: 0;
			}
		}
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	&__inputs {
		.basic-info {
			&__button {
				display: flex;
				justify-content: flex-end;
				margin-top: 6px;
			}
			&__inputs {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin: 16px 0;
			}
			&__item {
				margin-bottom: 16px;
				width: 367px;

				.just-validate-error-label {
					top: calc(100% - 2px);
				}
				.iti {
					.just-validate-error-label {
						top: calc(100% + 20px);
					}
					.iti__selected-flag {
						padding-left: 16px;
						margin-top: 7px;
						width: 80px;
					}
				}
				@include media-breakpoint-down(xl) {
					width: 100%;
				}
			}
		}
		.personal-info {
			&__button {
				display: flex;
				justify-content: flex-end;
				margin-top: 6px;
			}
			&__inputs {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin: 16px 0;
			}
			&__item {
				margin-bottom: 16px;
				width: 367px;

				@include media-breakpoint-down(xl) {
					width: 100%;
				}
			}
		}
	}
	&__basic {
		&-inputs {
			display: none;
		}
		&-edition {
			@include media-breakpoint-down(sm) {
				flex-direction: column;
				align-items: flex-start;
				padding-bottom: 20px;
			}
		}
	}
	&__personal {
		&-inputs {
			display: none;
		}
		&-edition {
			@include media-breakpoint-down(sm) {
				flex-direction: column;
				align-items: flex-start;
				padding-bottom: 20px;
			}
		}
	}

	.form-control {
		.form-control__label {
			background-color: #fff;
			color: $blue-900;
			&:after {
				border-color: rgba($blue-500, 0.2);
			}
		}
	}
}
