.account-subscribtion,
.account-subs-eba,
.account-subscribed {
	// display: none;
	&__banner {
		height: 288px;
		border-radius: 16px;
		background-color: blue;
		background: url(../../images/account-banner.png);
		background-size: cover;
		background-position: center;
		overflow: hidden;
		@include media-breakpoint-down(sm) {
			height: 526px;
		}
	}
	&__wrapper {
		display: flex;
		width: 353px;
		height: 100%;
		@include media-breakpoint-down(sm) {
			flex-direction: column-reverse;
			width: 100%;
		}
	}
	&__content {
		padding: 20px;
		@include media-breakpoint-down(sm) {
			padding: 0 10px;
			display: flex;
			flex-direction: column;
			margin-bottom: 30px;
		}
	}

	&__button {
		margin: 0 auto;
	}
	&__text {
		margin-bottom: 24px;
		width: 353px;
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
		&--title {
			font-size: 20px;
			font-weight: 400;
			color: #fff;
			@include media-breakpoint-down(sm) {
				text-align: center;
			}
			p {
				margin-bottom: 0;
			}
		}
		&--additional {
			display: flex;
			flex-direction: column;
			gap: 12px;
			margin-top: 12px;

			@include media-breakpoint-down(sm) {
				align-items: center;
			}

			span {
				color: #fff;
			}
			p {
				margin: 0;
			}
			font-size: 16px;
			font-weight: 400;
			color: rgba(255, 255, 255, 0.67);
		}
		&--item {
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}
	&__info {
		margin-top: 10px;
		&--basic {
			display: flex;
			flex-direction: column;
			margin-top: 36px;
		}
		&--personal {
			margin-top: 48px;
			margin-bottom: 98px;
		}
		&--item {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			min-height: 56px;
			padding: 6px 0;

			@include media-breakpoint-down(sm) {
				text-align: start;
			}
			h3 {
				margin: 0;
			}
			p {
				margin: 0;
			}
			span {
				color: $gray-800;
			}
			button {
				color: $blue-500;
			}
			&__description {
				display: flex;
				margin-right: 20px;
				gap: 10px;
				justify-content: space-between;
				@include media-breakpoint-down(sm) {
					display: block;
					width: auto;
				}
			}
			&__price {
				display: flex;
				gap: 10px;
				justify-content: space-between;
			}
		}
		&--line {
			width: 100%;
			height: 5px;
			color: green;
		}
	}
	&__trezub--mobile {
		display: none;
		@include media-breakpoint-down(sm) {
			background: url(../../images/yellow-trezub.svg);
			height: 50%;
			width: auto;
			background-position: center;
			display: block;
			padding-top: 10px;
			background-repeat: no-repeat;
			height: 50%;
			width: auto;
			background-position: center;
		}
	}
	&__trezub--desktop {
		display: block;
		position: relative;
		img {
			position: absolute;
			width: 350px;
			height: auto;
			top: -106px;
			max-width: none;
			left: 100px;
			@include media-breakpoint-down(xl) {
				left: -70px;
			}
			@include media-breakpoint-down(md) {
				left: 0;
			}
		}
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	.invoices-center {
		justify-content: center;
	}
	p.p-tooltip {
		display: flex;
		.icon {
			font-size: 24px;
		}
	}
}
