.account-sub-register-page {
	padding: 0 329px 255px;

	.account-subscription-page__payment {
		.form-control {
			background-color: transparent;
			border: none;
			height: auto;
		}
	}
	.account-subscription-page__checkbox.form-control {
		background-color: transparent;
		border: none;
		height: auto;
		margin-bottom: 64px;
	}
	.form-control__label.form-control__label--checkbox {
		display: none;
	}

	@include media-breakpoint-down(xl) {
		padding: 0 150px 150px;
	}

	@include media-breakpoint-down(lg) {
		padding-left: 20px;
		padding-right: 20px;
	}

	&:before {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 1440px;
		height: 683px;
		z-index: -1;
		background: $blue-100;
		background: linear-gradient(180deg, #e6f2ff 0%, rgba(230, 242, 255, 0) 100%),
			url('../../images/lead-image.webp');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	&__title {
		margin-top: 32px;
		margin-bottom: 30px;
	}

	&__title-form-wrapper {
		display: flex;
		justify-content: space-between;
	}

	&__divider {
		margin-top: 16px;
		width: 100%;
		height: 1px;
		align-self: stretch;
		background: rgba(0, 128, 255, 0.2);
	}

	&__form-title {
		color: $blue-900;
		font-size: 20px;
		font-weight: 400;
		line-height: 26px;
		letter-spacing: -0.1px;
	}

	&__link {
		color: $blue-500;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}

	&__registration {
		margin-bottom: 48px;
		margin-top: 48px;
	}

	&__form-validation {
		margin-top: 16px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 16px;

		@include media-breakpoint-down(sm) {
			grid-template-columns: 1fr;
		}
	}

	&__inputs-wrapper {
		margin-top: 16px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr;
		gap: 16px;

		@include media-breakpoint-down(sm) {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 1fr;
		}
	}

	&__base-label {
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		color: $grey-500;
	}

	&__phone-form {
		display: flex;
		width: 100%;
		gap: 4px;
		flex-direction: row;
	}

	&__passwd-form {
		position: relative;
		.toggle-password-button {
			position: absolute;
			right: 16px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.iti__flag-container {
		margin: 11px 0 0 3px;
	}
	.iti__selected-flag {
		padding-left: 16px;
		margin-top: 2px;
		width: 80px;
	}

	// TODO: Check for what
	// .form-control__label:after {
	// 	display: none;
	// }

	.icon-show {
		width: 20px;
		height: 20px;
		flex-shrink: 0;
	}

	.country-number {
		display: flex;
		max-height: 20px;
		border: none;
		outline: none;
		background: $white;
	}

	// .just-validate-error-label {
	// 	font-size: 11px;
	// 	font-weight: 500;
	// 	letter-spacing: 0.2px;
	// 	color: $red !important;
	// 	position: absolute;
	// 	z-index: 2;
	// 	left: 0;
	// 	top: calc(100% + 5px);
	// }
	.form-control {
		.form-control__label {
			background-color: #fff;
			color: $blue-900;
			&:after {
				border-color: rgba($blue-500, 0.2);
			}
		}
	}
}
