// Starter template

@import './header/header';
@import './footer/footer';

// Developer components

@import './language-dropdown/language-dropdown';
@import './quote/quote';
@import './invest/invest';
@import './rebuild/rebuild';
@import './economic-sectors/economic-sectors';
@import './service-providers/service-providers';
@import './list-item/list-item';
@import './breadcrumbs/breadcrumbs';
@import './home-info/home-info';
@import './investment-map/investment-map';

@import './list/list';
@import './modal/modal';
@import './inquiry/inquiry';

@import './email-form/email-form';

@import './submit-project/submit-project';
@import './advisory-board/advisory-board';
@import './startups-banner/startups-banner';
@import './talents/talents';
@import './startups/startups';
@import './startup-modal/startup-modal';

@import './authorization/authorization';
@import './project-subscription-dialog/project-subscription-dialog';
@import './profile-dropdown/profile-dropdown';
@import './account-sidebar/account-sidebar';
@import './account-main/account-main';
@import './account-subscribtion/account-subscribtion-main';
@import './account-subscribtion/account-subscribtion-main';
@import "./account-sub-register-page/account-sub-register-page";

@import "./success-val/success-val";
@import "./startup-list/startup-list";