.about-page {
	&__inner {
		padding: 44px 0 97px;

		h1 {
			margin-bottom: 16px;

			@include media-breakpoint-down(xl) {
				font-size: 48px;
				line-height: 1;
			}

			@include media-breakpoint-down(sm) {
				font-size: 32px;
				line-height: 1.25;
			}
		}

		p {
			font-size: 24px;
			line-height: 32px;
			color: $grey-500;

			@include media-breakpoint-down(xl) {
				font-size: 20px;
				line-height: 1.5;
			}

			@include media-breakpoint-down(sm) {
				font-size: 16px;
			}
		}
	}
}
