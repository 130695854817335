/**
 * Bootstrap
 */
@import './variables';
@import '../../vendor/bootstrap/bootstrap';

/**
  * Mixins
  */
@import '../../mixins/scss/sprite-img';
@import '../../mixins/scss/sprite-svg';
@import '../../mixins/scss/pos-center';
@import '../../mixins/scss/pos-strech';
@import '../../mixins/scss/letter-spacing';
@import '../../mixins/scss/gradient';
@import '../../mixins/scss/font-face';

/**
  * Base
  */
@import './reset';
@import './fonts';
@import './base';
@import './typography';
@import './png-icons';

/**
  * Vendor styles
  */
@import '../../vendor/vendor';

/**
  * Elements
  */
@import '../../elements/elements';

/**
  * Layouts
  */
@import '../../layouts/layouts';

/**
  * Components
  */

@import '../../components/components';
