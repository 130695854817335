.startup-modal {
	.modal-dialog {
		padding: 19px 0;

		@include media-breakpoint-down(md) {
			padding: 0;
		}
	}
	.modal-close {
		height: 44px;
		width: 44px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $grey-500;
		box-shadow: 0px 4px 32px 0px rgba(51, 51, 51, 0.04);
		color: $white;
		font-size: 20px;
		z-index: 3;

		&:hover .icon {
			color: $yellow-500;
		}
	}
	.modal-content {
		margin: 0 auto;
		max-height: 100dvh;
		height: 100%;
		padding: 0;
		width: auto;
		align-items: flex-start;
		justify-content: start;

		&-inner {
			padding: 0;
		}
	}

	.modal-inner {
		max-width: 752px;
	}

	&__head {
		height: 200px;
		position: relative;

		.text-page & {
			height: 100px;
		}

		@include media-breakpoint-down(lg) {
			height: 174px;
		}
		@include media-breakpoint-down(md) {
			height: 85px;
		}

		img {
			max-height: 100%;
			width: 100%;
			height: auto;
		}
	}

	&__body {
		padding: 36px 99px 63px;
		position: relative;

		@include media-breakpoint-down(lg) {
			padding: 36px 64px 48px;
		}

		@include media-breakpoint-down(md) {
			padding: 30px 20px 16px;
		}
		&-inner {
			width: 100%;
			position: relative;
		}
	}

	&__info {
		padding-bottom: 32px;
	}

	&__desc {
		font-size: 20px;
		font-weight: 300;
		line-height: 26px;
		letter-spacing: -0.1px;
		padding: 32px 0;
		border-top: 1px solid rgba($blue-500, 0.2);
		border-bottom: 1px solid rgba($blue-500, 0.2);
		margin-bottom: 32px;
		color: $blue-900;
	}

	&__img {
		height: 112px;
		width: 112px;
		border-radius: 50%;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: -90px;
		border: 3px solid white;
		left: 0;
		object-fit: cover;

		@include media-breakpoint-down(md) {
			height: 56px;
			width: 56px;
			top: -50px;
		}

		img {
			height: 100%;
		}
	}

	&__round {
		display: flex;
		justify-content: flex-end;
		gap: 4px;
		margin-bottom: 32px;

		&-txt {
			font-size: 16px;
			font-weight: 300;
			line-height: 20px;
			color: $grey-500;

			@include media-breakpoint-down(md) {
				font-size: 14px;
			}
		}
		&-val {
			font-size: 16px;
			font-weight: 300;
			line-height: 20px;
			color: $blue-900;

			@include media-breakpoint-down(md) {
				font-size: 14px;
			}
		}
	}
	&__name {
		font-size: 32px;
		font-weight: 500;
		line-height: 40px;
		letter-spacing: -0.32px;
		color: $blue-900;

		@include media-breakpoint-down(md) {
			font-size: 24px;
			line-height: 32px;
		}
	}
	&__link {
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		color: $blue-500;
		margin-bottom: 4px;
		display: inline-flex;
	}
	&__category {
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		color: $grey-400;
	}
	&__text {
		font-weight: 300;
		h2 {
			font-size: 20px;
			font-weight: 400;
			line-height: 26px;
			letter-spacing: -0.1px;
			color: $blue-900;
			margin-bottom: 12px;
		}
		ul {
			margin-bottom: 24px;
		}
		p {
			margin-bottom: 24px;
			line-height: 24px;
		}
	}
	&__btn {
		display: flex;
		margin-top: 32px;
		justify-content: flex-end;

		@include media-breakpoint-down(md) {
			width: 100%;
			justify-content: center;
		}

		.button {
			@include media-breakpoint-down(md) {
				width: 100%;
			}
		}
	}
}
