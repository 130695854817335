.rebuild {
	padding: 96px 0;

	@include media-breakpoint-down(lg) {
		padding: 0;
	}

	&__inner {
		gap: 110px;
		display: flex;
		justify-content: center;
		align-items: center;

		@include media-breakpoint-down(xl) {
			gap: 30px;
			justify-content: flex-end;
		}

		@include media-breakpoint-down(lg) {
			flex-direction: column-reverse;
			gap: 0;
		}
	}

	&__info {
		max-width: 524px;

		@include media-breakpoint-down(xl) {
			max-width: 430px;
		}

		@include media-breakpoint-down(lg) {
			max-width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			padding: 32px 0 64px;
		}

		@include media-breakpoint-down(md) {
			text-align: left;
		}
	}
	&__title {
		display: flex;
		align-items: center;
		gap: 16px;
		position: relative;
		margin-bottom: 16px;

		@include media-breakpoint-down(xl) {
			align-items: flex-start;
		}

		@include media-breakpoint-down(lg) {
			margin-bottom: 12px;
		}

		&-text {
			margin: 0;
			font-size: 48px;
			font-weight: 600;
			line-height: 48px;
			letter-spacing: -0.72px;

			@include media-breakpoint-down(lg) {
				font-size: 32px;
				font-weight: 500;
				line-height: 40px;
				letter-spacing: -0.32px;
			}
		}

		svg {
			width: 48px;
			height: 48px;
			position: absolute;
			right: calc(100% + 16px);
			@include media-breakpoint-down(xl) {
				position: relative;
				right: auto;
			}

			@include media-breakpoint-down(lg) {
				height: 40px;
				width: 40px;
			}
		}
	}
	&__desc {
		font-size: 24px;
		font-weight: 300;
		line-height: 32px;
		letter-spacing: -0.24px;
		margin-bottom: 32px;

		@include media-breakpoint-down(lg) {
			font-size: 20px;
			line-height: 26px;
			letter-spacing: -0.1px;
		}

		br {
			@include media-breakpoint-down(xl) {
				display: none;
			}
		}
	}

	&__button {
		font-size: 14px;

		@include media-breakpoint-down(lg) {
			padding: 14px 20px;
			line-height: 22px;
		}

		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}
	&__img {
		width: 368px;
		height: 448px;
		border-radius: 16px;
		overflow: hidden;

		@include media-breakpoint-down(xl) {
			width: 350px;
			height: 426px;
		}

		@include media-breakpoint-down(lg) {
			width: 100vw;
			height: 258px;
			border-radius: 0;
		}

		@include media-breakpoint-down(md) {
			height: 240px;
		}

		picture {
			height: 100%;
			width: 100%;
			min-height: 100%;
		}

		img {
			object-position: top;
			object-fit: cover;
			height: 100%;
			width: 100%;
			min-height: 100%;
		}
	}
}
