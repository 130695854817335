.project-page {
	padding: 24px 0 96px;

	position: relative;

	@include media-breakpoint-down(xl) {
		padding: 24px 0 65px;
	}

	&__top {
		display: flex;
		flex-direction: column;
		gap: 23px;
		margin-bottom: 24px;

		@include media-breakpoint-down(md) {
			gap: 15px;
			margin: 0;
		}

		&-line {
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: space-between;
		}
	}

	&__date {
		font-size: 14px;
		color: $grey-300;
		font-weight: 400;
		line-height: 22px;
		flex-shrink: 0;
	}

	&__head {
		display: flex;
		position: relative;
	}

	&__back {
		font-size: 64px;
		color: $grey-500;
		transition: color 0.2s ease-in-out;
		display: flex;
		align-items: center;
		position: absolute;
		right: calc(100% + 21px);
		top: 10px;

		@include media-breakpoint-down(xl) {
			display: none;
		}

		&:hover {
			color: $blue-900;
		}
	}

	&__main {
		display: flex;
		justify-content: space-between;
		gap: 24px;
		margin-top: 26px;
		margin-bottom: 80px;

		@include media-breakpoint-down(md) {
			margin-top: 17px;
		}
	}

	&__left {
		max-width: 728px;
		width: 100%;

		&:only-child {
			max-width: none;
		}
	}

	&__info--mobile {
		display: none;

		@include media-breakpoint-down(lg) {
			display: block;
		}
	}

	&__sidebar {
		width: 364px;
		flex-shrink: 0;
		margin-top: 6px;

		@include media-breakpoint-down(xl) {
			width: 280px;
		}

		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	&__tag {
		background-color: $yellow-500;
		border-radius: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 3px 12px;
		font-size: 16px;
		color: $blue-900;
		font-weight: 500;
		line-height: 24px;

		&:hover {
			color: $blue-900;
		}

		@include media-breakpoint-down(md) {
			font-size: 14px;
			font-weight: 500;
			line-height: 22px;
			padding: 1px 10px;
		}
	}

	&__title {
		/*font-size: 73px;*/
		font-weight: 600;
		/*line-height: 80px;*/
		letter-spacing: -1.46px;
		margin-bottom: 24px;
		word-break: break-word;

		@include media-breakpoint-down(xl) {
			font-size: 48px;
			font-weight: 600;
			line-height: 48px;
			letter-spacing: -0.72px;
		}

		@include media-breakpoint-down(md) {
			font-size: 32px;
			font-weight: 500;
			line-height: 40px;
			letter-spacing: -0.32px;
		}
	}

	&__subtitle {
		font-size: 24px;
		font-weight: 300;
		line-height: 32px;
		letter-spacing: -0.24px;
		margin-bottom: 24px;
		color: $blue-900;

		@include media-breakpoint-down(lg) {
			margin-bottom: 31px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 20px;
			line-height: 26px;
			letter-spacing: -0.1px;
		}
	}

	&__img {
		border-radius: 12px;
		overflow: hidden;
		max-width: 726px;
		position: relative;
		margin-bottom: 24px;

		@include media-breakpoint-down(md) {
			border-radius: 3.784px;
		}

		&:after {
			content: '';
			display: block;
			padding-bottom: 56.5%;
		}

		picture {
			height: 100%;
			width: 100%;
			min-height: 100%;
			position: absolute;
		}

		img {
			/*object-fit: cover;
			height: 100%;
			width: 100%;
			min-height: 100%;*/
			margin: 0 auto;
			display: block;
		}
	}

	&__desc {
		display: flex;
		align-items: stretch;
		flex-direction: column;
		color: $blue-900;

		@include media-breakpoint-down(xl) {
			max-width: 536px;
		}

		@include media-breakpoint-down(lg) {
			max-width: 636px;
		}

		@include media-breakpoint-down(md) {
			padding-right: 24px;
		}

		ol {
			counter-reset: item;
			& > li {
				position: relative;
				padding-left: 16px;
				&:before {
					content: counter(item) '. ';
					counter-increment: item;
					position: absolute;
					left: 0;
					top: 0;
				}
			}
		}

		ul {
			& > li {
				position: relative;
				padding-left: 16px;

				&:before {
					content: '';
					display: block;
					width: 5px;
					height: 5px;
					border-radius: 50%;
					background-color: inherit;
					margin-right: 7px;
					position: absolute;
					left: 0;
					top: 10px;
					background-color: $grey-500;
				}
			}
		}
	}

	&__money {
		margin-bottom: 32px;

		@include media-breakpoint-down(lg) {
			display: none;
		}
		&-val {
			font-size: 40px;
			display: flex;
			font-weight: 600;
			line-height: 48px;
			letter-spacing: -0.72px;
			margin-bottom: 4px;
			    color: #002266;

			.animated-count {
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}
	&__stats {
		display: flex;
		flex-direction: column;
		margin-bottom: 32px;
		padding-left: 6px;

		@include media-breakpoint-down(lg) {
			margin-bottom: 24px;
		}
	}

	&__stat {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding: 16px 0;
		gap: 20px;
		position: relative;

		&.opportunity {
			.project-page__stat-val {
				white-space: pre-wrap;
				display: flex;
			}
		}

		&:not(:last-child) {
			border-bottom: 1px solid rgba($blue-500, 0.2);
		}

		&-txt {
			max-width: 130px;
			width: 100%;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			flex-shrink: 0;

			&-empty {
				max-width: 100%;
			}
		}

		&-val {
			color: $blue-900;
			max-width: 100%;
			/*display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			overflow: hidden;*/
			text-align: right;
		}

		&-link {
			color: $blue-500;
			display: flex;
			align-items: center;
			.icon {
				font-size: 24px;
			}
		}
		&-share {
			cursor: pointer;

			&:hover {
				.project-page__stat-share-title {
					color: $blue-600;
				}
				.project-page__stat-share-list {
					opacity: 1;
					pointer-events: inherit;
				}
			}
			&-title {
				color: $blue-500;
				display: flex;
				align-items: center;
				white-space: nowrap;
				.icon {
					font-size: 24px;
				}
			}
			&-list {
				position: absolute;
				box-shadow: 0px 8px 48px 0px rgba(0, 0, 102, 0.1);
				display: flex;
				flex-direction: column;
				border-radius: 12px;
				background-color: $white;
				top: calc(100% - 16px);
				right: 0;
				opacity: 0;
				transition: opacity 0.3s ease-in-out;
				pointer-events: none;
				z-index: 2;
				padding: 4px;
			}
			&-link {
				padding: 10px 16px;
				gap: 8px;
				display: flex;
				align-items: center;
				border-radius: 8px;
				transition: all 0.3s ease-in-out;

				&:hover {
					background: $grey-50;
					color: initial;
				}

				.icon {
					font-size: 20px;
					color: $grey-500;
					flex-shrink: 0;
				}
			}
		}
	}

	&__btn {
		display: flex;
		justify-content: center;
		flex-grow: 1;

		@include media-breakpoint-down(lg) {
			display: none;
		}

		button {
			width: 100%;
		}
	}

	&__lists {
		gap: 86px;
		margin-top: 52px;
		display: flex;
		flex-direction: column;

		@include media-breakpoint-down(xl) {
			margin-top: 70px;
			gap: 55px;
		}

		@include media-breakpoint-down(lg) {
			margin-top: 16px;
			gap: 20px;
		}
		@include media-breakpoint-down(md) {
			gap: 44px;
		}
	}

	.list {
		&__head {
			@include media-breakpoint-down(lg) {
				margin-bottom: 40px;
			}

			@include media-breakpoint-down(md) {
				margin-bottom: 22px;
			}
		}
		&-item {
			@include media-breakpoint-down(lg) {
				padding: 28.5px 12px;
			}
			@include media-breakpoint-down(md) {
				padding: 29px 3px 21px;
			}
		}

		&__load-more {
			@include media-breakpoint-down(lg) {
				margin-top: 46px;
			}
			@include media-breakpoint-down(md) {
				margin-top: 24px;
			}
		}
	}

	&__footer {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		box-shadow: 0px 16px 48px 0px rgba(0, 77, 229, 0.16);
		background: $white;
		z-index: 2;
		display: none;
		padding: 12px 0;

		&-inner {
			justify-content: space-between;
			align-items: center;
			display: flex;
		}

		.button {
			@include media-breakpoint-down(md) {
				font-size: 14px;
				padding: 16px 24px;
				.icon {
					display: none;
				}
			}
			@include media-breakpoint-down(sm) {
				padding: 12px 20px;
			}
		}

		.project-page__money-val {
			font-size: 24px;
			font-weight: 500;
			line-height: 32px;

			@include media-breakpoint-down(md) {
				font-size: 20px;
				line-height: 26px;
				letter-spacing: -0.1px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 16px;
				line-height: 24px;
			}
		}

		.project-page__money-txt {
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			display: inline-block;

			@include media-breakpoint-down(md) {
				line-height: 18px;
			}

			@include media-breakpoint-down(sm) {
				max-width: 145px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}

		@include media-breakpoint-down(lg) {
			display: block;
		}
	}
}
