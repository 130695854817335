.quote {
	padding: 128px 0 120px;
	position: relative;
	background-color: $blue-100;

	&.quote--hasBg {
		background-color: transparent;
	}

	@include media-breakpoint-down(xl) {
		padding: 128px 0;
	}

	@include media-breakpoint-down(lg) {
		padding: 96px 0 90px;
	}

	@include media-breakpoint-down(md) {
		padding: 64px 0 56px;
	}

	&__img {
		z-index: 2;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;

		picture {
			height: 100%;
			width: 100%;
			min-height: 100%;
		}

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
			min-height: 100%;
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		z-index: 3;

		@include media-breakpoint-down(xl) {
			max-width: 730px;
			margin: auto;
		}
	}

	&__author {
		font-size: 48px;
		margin-top: 16px;
		display: flex;
		color: $yellow-500;
	}

	&__text {
		* {
			font-size: 32px;
			font-weight: 400;
			line-height: 40px;
			letter-spacing: -0.48px;
			color: $blue-900;
			margin: 0;
			text-align: center;

			.quote--hasBg & {
				color: $white;
			}

			@include media-breakpoint-down(lg) {
				font-size: 24px;
				line-height: 32px;
				letter-spacing: -0.24px;
			}

			@include media-breakpoint-down(md) {
				font-size: 20px;
				line-height: 26px;
				letter-spacing: -0.1px;
			}
		}
	}
}
