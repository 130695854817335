.list-item {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	border-bottom: 1px solid rgba(0, 128, 255, 0.2);
	gap: 20px;
	padding: 40.5px 12px;
	transition: background 0.3s ease;

	@include media-breakpoint-down(xl) {
		padding: 41.5px 12px;
	}

	@include media-breakpoint-down(lg) {
		padding: 27.5px 12px;
	}

	@include media-breakpoint-down(md) {
		padding: 25px 3px;
		gap: 12px;
	}

	&:hover {
		background: rgba(0, 128, 255, 0.04);
		.list-item__title,
		.list-item__ico {
			color: $blue-900;
		}
		.list-item__category {
			color: $grey-400;
		}

		.icon {
			transform: none;
			.line {
				display: block;
				opacity: 1;
			}
		}
	}

	&:first-child {
		border-top: 1px solid rgba(0, 128, 255, 0.2);
	}

	&__left {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		max-width: 658px;
		width: 100%;

		&-top {
			display: flex;
			align-items: flex-start;
		}

		@include media-breakpoint-down(xl) {
			max-width: 406px;
		}

		@include media-breakpoint-down(lg) {
			max-width: 356px;
		}

		@include media-breakpoint-down(md) {
			flex-direction: column;
			max-width: none;
		}
	}

	&__right {
		display: flex;
		align-items: center;
		gap: 24px;
		flex-grow: 1;
		justify-content: end;

		@include media-breakpoint-down(lg) {
			justify-content: flex-end;
		}
	}

	&__mobile {
		display: none;
		align-items: center;
		justify-content: center;
		gap: 8px;
		margin-top: 6px;

		@include media-breakpoint-down(lg) {
			display: flex;
		}
	}

	&__group {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 24px;
	}

	&__title {
		font-size: 24px;
		font-weight: 400;
		line-height: 32px;
		letter-spacing: -0.24px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;

		@include media-breakpoint-down(lg) {
			font-size: 20px;
			line-height: 26px;
			letter-spacing: -0.1px;
		}
	}

	&__type {
		margin: 0 24px;
		padding: 1px 12px;
		border-radius: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		white-space: nowrap;
		font-size: 14px;
		line-height: 22px;
		font-weight: 500;
		color: $grey-500;
		background-color: rgba(0, 128, 255, 0.08);

		.project-page__top-line &{
			margin:0;
		}

		@include media-breakpoint-down(lg) {
			display: none;
			margin: 0;
			font-weight: 400;
		}

		&-rebuild {
			color: $blue-900;
			background-color: $yellow-500;
		}

		&--mob {
			display: none;
			text-align: left;
			max-width: 100%;

			@include media-breakpoint-down(lg) {
				display: flex;
			}
		}
	}

	&__category {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.1px;
		color: $grey-400;
		max-width: 256px;
		text-align: right;

		@include media-breakpoint-down(lg) {
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
		}

		@include media-breakpoint-down(md) {
			display: none;
			font-size: 14px;
		}

		&--mob {
			display: none;
			text-align: left;
			max-width: 100%;

			@include media-breakpoint-down(md) {
				display: flex;
			}
		}
	}

	&__ico {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $yellow-500;
		font-size: 32px;
		flex-shrink: 0;

		@include media-breakpoint-down(lg) {
			font-size: 25px;
			width: 32px;
			height: 32px;
		}

		.icon {
			transition: all 0.3s ease;
			transform: translateX(-4px);

			@include media-breakpoint-down(lg) {
				transform: translateX(-2px);
			}

			.line {
				display: none;
				opacity: 0;
			}
		}
	}
}
