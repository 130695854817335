.header {
	position: relative;
	z-index: 4;

	&.no-shadow {
		.header__bottom {
			box-shadow: none;
		}
	}

	&__divider {
		margin-top: 48px;
		margin-bottom: -37px;
		display: flex;
		width: 100%;
		height: 1px;
		flex-shrink: 0;
		background: $grey-100;

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	&.active {
		.header-menu {
			height: 100%;
		}

		.header__menu-open-icon {
			display: none;
		}

		.header__menu-close-icon {
			display: block;
		}

		.header-menu {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			background-color: $white;
			height: calc(100dvh - 104px);
			padding: 0 20px;
			border-top: 1px solid $grey-100;
		}

		.header-menu-dropdown__lists {
			justify-content: flex-start;
		}

		.header-menu__list {
			flex-direction: column;
			gap: 0;
			height: calc(100% - 40px);
			overflow-y: scroll;
		}

		.header-menu__btn,
		.header-menu__link {
			width: 100%;
			display: flex;
			justify-content: space-between;
			color: $grey-500;
			padding: 16px 0;
			white-space: nowrap;

			&:hover {
				color: $blue-900;
			}
			.current-menu-item & {
				color: $blue-900;
			}
		}
		.header-menu__item {
			@include media-breakpoint-up(lg) {
				height: 100%;
			}
			&:not(:last-child) {
				border-bottom: 1px solid $grey-100;
			}
		}

		.header__language-dropdown--mobile {
			display: block;
		}

		.header-menu__email {
			display: block;
		}
	}

	&__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-grow: 1;
	}

	&__menu-toggle {
		display: none;

		@include media-breakpoint-down(lg) {
			display: flex;
		}
	}

	&__top {
		background-color: $blue-900;
		padding: 8px 16px;
	}

	&__logos {
		display: flex;
		align-items: center;
		gap: 17px;
	}

	&__logo {
		height: 24px;
		flex-shrink: 0;

		picture {
			background-color: transparent;
			width: 100%;
			height: 100%;
			max-height: 100%;
		}

		img {
			height: 100%;
		}
	}

	&__left {
		display: flex;
		align-items: stretch;
		flex-grow: 1;
	}

	&__right {
		display: flex;
		align-items: center;
		gap: 20px;
	}

	&__bottom-wrap {
		min-height: 64px;
	}

	&__bottom {
		position: relative;
		padding: 0 16px;
		box-shadow: 0px 16px 48px 0px rgba(0, 77, 229, 0.16);
		background-color: $white;
		min-height: 64px;
		display: flex;
		width: 100%;
		transition: all 0.2s ease;

		&.fixed {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			transform: translateY(0);
		}

		&.unvisible {
			transform: translateY(-40px);
		}

		.map-popup-is-opened & {
			position: relative;
			transform: none;
		}
	}

	&__ua {
		font-size: 40px;
		margin-right: 94px;
		display: flex;
		align-items: center;
		color: $yellow-500;

		@include media-breakpoint-down(xl) {
			margin-right: 10px;
		}
	}

	&__language-dropdown {
		@include media-breakpoint-down(md) {
			display: none;
		}

		&--mobile {
			display: none;
		}
	}

	&-menu {
		@include media-breakpoint-down(lg) {
			display: none;
		}

		&-bottom-button {
			width: 100%;
			display: flex;
			justify-content: start;
			border-top: 1px solid $grey-100;
			padding-top: 16px;
			margin-top: 48px;

			@include media-breakpoint-down(lg) {
				margin-top: 12px;
				padding: 16px 20px;
				border: none;
			}

			&-wrap {
				display: flex;
				align-items: center;
				gap: 2px;
			}

			&-icon {
				color: $blue-500;
			}

			&-a {
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				color: $blue-500;
			}
		}

		&__list {
			@include media-breakpoint-up(lg) {
				height: 100%;
			}
			display: flex;
			align-items: stretch;
			height: 100%;
			gap: 28px;

			@include media-breakpoint-down(xl) {
				gap: 16px;
			}
		}

		&__new-wrap {
			&-ellipse {
				display: flex;
				width: 6px;
				height: 6px;
				border-radius: 24px;
				margin-right: 6px;
				background: $yellow;
			}
		}

		&__link,
		&__btn {
			color: $blue-900;
			display: flex;
			align-items: center;
			transition: color 0.3s ease-in-out;
			height: 100%;
			font-size: 14px;
			white-space: nowrap;

			@include media-breakpoint-down(xl) {
				font-size: 13px;
			}

			.icon {
				font-size: 24px;
				margin-left: 2px;
				transition: transform 0.3s ease-in-out;

				@include media-breakpoint-down(lg) {
					transform: rotateZ(-90deg);
				}
			}
		}
		&__item {
			display: flex;
			flex-direction: row;
			align-items: center;
			@include media-breakpoint-up(lg) {
				&:hover {
					.header-menu__dropdown {
						transform: translateY(0);
						opacity: 1;
						pointer-events: inherit;

						@include media-breakpoint-down(lg) {
							transform: translateY(0) translateX(0);
							z-index: 2;
							overflow-y: scroll;
						}
					}
				}
			}
			@include media-breakpoint-up(lg) {
				&:hover,
				&.current-menu-item {
					.header-menu__link,
					.header-menu__btn {
						color: $blue-600;

						.icon {
							@include media-breakpoint-down(lg) {
								transform: rotateZ(-90deg);
							}
						}
					}
				}
			}
			&.disabled {
				pointer-events: none;
				opacity: 0.2;
			}
			&.active {
				@include media-breakpoint-down(lg) {
					border-color: transparent !important;
				}

				.header-menu__btn {
					color: $yellow-550;
					.icon {
						transform: rotateX(180deg);
					}
				}

				.header-menu-dropdown {
					transform: translateY(0);
					opacity: 1;
					pointer-events: inherit;

					@include media-breakpoint-down(lg) {
						transform: translateY(0) translateX(0);
						z-index: 2;
						overflow-y: scroll;
					}
				}
			}
		}
		&-dropdown {
			box-shadow: 0px 30px 48px 0px rgba(0, 77, 229, 0.16);
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
			border-top: 1px solid $grey-50;
			border-bottom: 3px solid $yellow-500;
			background-color: $white;
			overflow: hidden;
			transition:
				transform 0.4s ease,
				opacity 0.2s ease;
			opacity: 0;
			transform: translateY(-3vh);
			z-index: -2;
			pointer-events: none;

			@include media-breakpoint-down(lg) {
				transform: translateY(0) translateX(100vh);
				top: 0;
				bottom: 0;
				border: none;
				box-shadow: none;
			}

			&__inner {
				max-width: 1140px;
				margin: 0 150px;
				padding: 48px 0 48px;

				@include media-breakpoint-down(xl) {
					margin: 0 36px;
				}

				@include media-breakpoint-down(lg) {
					max-width: 100%;
					margin: 0;
					padding: 0;
				}
			}

			&__title {
				margin-bottom: 16px;
				display: flex;
				align-items: center;
				gap: 3px;
				pointer-events: none;
				width: 100%;

				@include media-breakpoint-down(lg) {
					padding: 11.5px;
					border-bottom: 1px solid $grey-100;
					margin-bottom: 0;
					pointer-events: inherit;
					box-shadow: 0px 2px 20px 0px rgba(0, 77, 229, 0);
					transition: box-shadow 0.2s ease;
					&.scrolled {
						box-shadow: 0px 2px 20px 0px rgba(0, 77, 229, 0.12);
					}

					&:hover .icon {
						color: $blue-900;
					}
				}

				&-icon {
					font-size: 32px;
					color: $grey-500;
					display: none;
					transition: color 0.3s ease-in-out;

					@include media-breakpoint-down(lg) {
						display: block;
					}
				}

				&-txt {
					font-size: 14px;
					line-height: 22px;
					margin: 0;

					@include media-breakpoint-down(lg) {
						font-size: 16px;
						font-weight: 500;
						line-height: 24px;
					}
				}

				&-txt-bold {
					font-size: 14px;
					line-height: 22px;
					margin: 0;
					font-weight: 500;
					color: $blue-900;
				}
			}

			&__lists {
				display: flex;
				gap: 24px;

				@include media-breakpoint-down(lg) {
					flex-direction: column;
					gap: 0;
					padding: 0 20px;
					height: 100%;
				}
			}

			&__list {
				@include media-breakpoint-up(lg) {
					height: 100%;
				}
				gap: 12px;
				display: flex;
				flex-direction: column;

				@include media-breakpoint-down(lg) {
					gap: 0;
				}
			}

			&__item {
				display: flex;
				max-width: 100%;
				width: 100%;
				flex-grow: 1;

				@include media-breakpoint-down(lg) {
					border-bottom: 1px solid $grey-100;
				}

				@include media-breakpoint-up(xl) {
					max-width: 267px;
					width: 267px;
				}
			}

			&__link {
				font-size: 14px;
				line-height: 22px;
				color: $grey-500;

				&:hover {
					color: $blue-900;
				}

				@include media-breakpoint-down(lg) {
					padding: 16px 0;
					flex-grow: 1;
				}
			}
		}

		&__footer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 16px;
		}

		&__email {
			color: $blue-500;
			display: none;
		}
	}

	&__btn {
		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	&__menu-open,
	&__menu-close {
		&-icon {
			display: none;
			font-size: 32px;
			color: $blue-900;
		}
	}

	&__menu-open-icon {
		@include media-breakpoint-down(lg) {
			display: block;
		}
	}

	.header__btn {
		flex-shrink: 0;
		@include media-breakpoint-down(xl) {
			font-size: 12px;
			padding: 10px;
		}
	}
}
