/**
 * Font
 * ---------------------------------------------
 */
$font-family-base: 'Geologica', sans-serif;
$enable-smooth-scroll: true;

/**
 * Colors
 * ---------------------------------------------
 */
$white: #fff;
$black: #000;

$yellow-500: var(--accent-color);
$yellow-550: var(--accent-color-hover);

$red: #fa5332;
$green: #23cf20;

$yellow: #FAC519;

$blue-100: #e6f2ff;
$blue-200: #acd5ff;
$blue-300: #73b9ff;
$blue-400: #3a9cff;
$blue-500: #0080ff;
$blue-500-02: rgba(0, 128, 255, 0.2);
$blue-600: #0068d9;
$blue-700: #0051b2;
$blue-800: #00398c;
$blue-900: #002266;
$blue-1000: #001338;

$grey-50: #f4f5f7;
$grey-100: #e9ebf0;
$grey-200: #cad1e1;
$grey-300: #aab7d1;
$grey-400: #8b9dc2;
$grey-500: #6b83b2;
$grey-600: #5b719c;
$grey-700: #4c5f86;
$grey-800: #3c4d70;
$grey-900: #2d3b59;

/**
 * Links
 * --------------------------------------
 */

$link-decoration: none;
$link-color: $blue-900;
$link-hover-color: $blue-600;
$nav-link-transition: color 0.3s ease-in-out;

$headings-color: $blue-900;
$headings-font-weight: 600;

$h1-font-size: 48px;
$h2-font-size: 32px;
$h3-font-size: 24px;

/**
 * Shadow
 * ---------------------------------------------
 */
$box-shadow-blue: 0px 16px 48px 0px rgba(0, 77, 229, 0.16);
$box-shadow-blue-hover: 0px 32px 64px 0px rgba(0, 77, 229, 0.25);

/**
 * Body
 * ---------------------------------------------
 */
$font-size-base: 1rem;
$body-color: $grey-500;
$body-bg: $white;

// Grid columns

$grid-gutter-width: 40px;

// Grid breakpoints

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 728px,
	lg: 960px,
	xl: 1200px
);

// Grid containers

$container-max-widths: (
	sm: 540px + $grid-gutter-width,
	md: 660px + $grid-gutter-width,
	lg: 888px + $grid-gutter-width,
	xl: 1140px + $grid-gutter-width
);
