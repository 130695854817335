.form-control {
	position: relative;
	padding: 0;
	border: none;
	font-size: 16px;
	display: flex;
	flex-direction: column;
	background: none;
	margin-bottom: 15px;

	&--textarea {
		margin-bottom: 48px;
	}

	&--file {
		.form-control__inner {
			margin-top: 16px;
			display: flex;
			align-items: center;
		}
		.form-control__input {
			display: none;
		}
	}

	&--checkbox {
		margin-top: 48px;

		.form-control__text {
			position: relative;
			cursor: pointer;
			margin-top: 12px;
			display: flex;
			font-size: 14px;
			line-height: 22px;
		}
		.form-control__text:before {
			content: '';
			position: relative;
			cursor: pointer;
			margin-right: 8px;
			border-radius: 6px;
			border: 1.5px solid var(--grey-200, #cad1e1);
			height: 20px;
			width: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
		}

		.form-control__input {
			display: none;
		}

		.form-control__input:checked ~ .form-control__text:after {
			content: '';
			display: block;
			position: absolute;
			top: 4px;
			left: 7px;
			width: 6px;
			height: 10px;
			border: solid $blue-900;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
		}
	}

	&__file {
		display: flex;
		align-items: center;
		margin-left: 12px;
		gap: 4px;
		flex-grow: 1;

		&-name {
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 60%;
			overflow: hidden;
		}

		&-remove {
			display: none;
			flex-shrink: 0;

			&.active {
				display: flex;
			}
		}
	}

	&.hasError {
		&.form-control--checkbox .form-control__text:before {
			border-color: $red;
		}
		.form-control__label:after {
			border-color: $red;
		}

		.form-control__input:is(textarea) {
			border-color: $red;
		}
	}

	&__label {
		position: relative;
		display: flex;
		padding: 21px 0 22.5px;
		color: $grey-500;

		.isFlat & {
			padding: 26px 16px 10px;
			background-color: $grey-50;
			border-radius: 8px;
		}

		&--textarea,
		&--file,
		&--checkbox {
			padding: 0;
			margin: 0;

			span {
				position: relative;
				transform: none;
				color: $blue-900;
			}

			&:after {
				display: none !important;
			}
		}

		&:after {
			content: '';
			display: flex;
			width: 100%;
			position: absolute;
			left: 0;
			bottom: 0;
			height: 2px;
			border-width: 1px;
			border-style: solid;
			border-radius: 0.5px;
			border-color: $grey-100;
			pointer-events: none;
			transition: border-color 0.2s ease-in-out;
		}

		.isFlat &:after {
			height: 100%;
			border-width: 2px;
			border-radius: 8px;
			border-color: $grey-50;
		}

		&.active {
			.form-control__ttl {
				font-size: 12px;
				transform: translateY(0px);
			}
		}
		.isFlat &.active {
			.form-control__ttl {
				transform: translateY(12px);
			}
		}

		&:focus-within {
			&:after {
				border-color: $blue-500 !important;
			}
		}

		&:focus-within > .form-control__ttl,
		.form-control__input:not(:placeholder-shown) + .form-control__ttl {
			font-size: 12px;
			transform: translateY(0px);
		}

		.isFlat &:focus-within > .form-control__ttl,
		.isFlat & .form-control__input:not(:placeholder-shown) + .form-control__ttl {
			transform: translateY(12px);
		}
	}

	&__input {
		width: 100%;
		border: none;
		outline: none;
		height: 20px;
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		padding: 0;
		resize: none;
		background-color: transparent !important;
		color: $blue-900;

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus {
			-webkit-box-shadow: 0 0 0px 1000px transparent inset;
			transition: background-color 5000s ease-in-out 0s;
		}

		&::placeholder {
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
			color: $grey-200;
		}

		&:is(textarea) {
			resize: none;
			overflow: hidden;
			min-height: 63.5px;
			height: auto;
			border-bottom: 2px solid #e9ebf0;
			padding: 25px 0 0;
			transition: border-color 0.3s ease-in-out;

			&::placeholder {
				transition: opacity 0.3s ease-in-out;
			}

			&:focus {
				border-color: $blue-500;

				&::placeholder {
					opacity: 0;
				}
			}
		}

		&:not(textarea) {
			&::placeholder {
				opacity: 0;
			}
		}
	}

	&__ttl {
		position: absolute;
		top: 0;
		left: 0;
		transform: translateY(100%);
		transition-duration: 300ms;
		font-size: 16px;
		line-height: 1.2;

		@include media-breakpoint-down(md) {
			font-size: 14px;
		}

		.isFlat & {
			left: 16px;
		}
	}

	&__file-btn {
		display: flex;
		padding: 12px 20px;
		align-items: center;
		gap: 2px;
		background-color: $yellow-500;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 22px;
		color: $blue-900;
		border-radius: 100px;
		flex-shrink: 0;

		&:hover {
			background-color: $yellow-550;
		}
	}

	&__error {
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 0.2px;
		color: $red-500 !important;
		position: absolute;
		left: 0;
		top: calc(100% - 0.2em);
		z-index: 2;
	}

	&__inner {
		// position: relative;
		display: flex;
	}
}

.title {
	font-size: 16px;
	font-weight: 400;
	color: $blue-900;
	line-height: 24px;
}

.iti {
	position: initial;
	height: 20px;
}

.iti__flag {
	background-image: url('../images/flags.png') !important;
}

.iti__selected-flag {
	padding: 0;
	padding-top: 7px;
}

.iti__arrow {
	border: none;
	display: flex;
	width: 16px;
	height: 16px;
	margin-top: -3px;
	&:after {
		content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMgNkw3Ljg1ODU4IDEwLjg1ODZDNy45MzY2OCAxMC45MzY3IDguMDYzMzIgMTAuOTM2NyA4LjE0MTQyIDEwLjg1ODZMMTMgNiIgc3Ryb2tlPSIjMDAyMjY2IiBzdHJva2Utd2lkdGg9IjEuMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=);
	}
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
	background-color: transparent !important;
}

.iti__country-list {
	z-index: 3;

	@include media-breakpoint-down(sm) {
		top: auto !important;
		max-height: 90vh !important;
	}
}

.tel-prefix {
	color: initial;
	position: relative;
	left: 50px;
}

.tel-select input {
	padding-left: 55px !important;
}

.iti__selected-flag {
	padding-top: 0;
}

.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
	right: 0;
}

.iti__country-list {
	left: 0;
	right: 0;
	max-height: 165px;
	box-shadow: none;
	border-color: #e9ebf0;
}

.iti__divider {
	border-color: rgba(0, 128, 255, 0.2);
}

.iti__flag-box,
.iti__country-name {
	color: $grey-500;
}

.iti__dial-code {
	color: $blue-900;
	margin-left: auto;
}
.iti__country-name {
	color: #6b83b2;
	max-width: 150px;
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	transition: color 0.3s ease-in-out;
}

.iti__country {
	&:hover {
		background-color: #f4f5f7bb;
		.iti__country-name {
			color: $blue-500;
		}
	}
}

.iti input,
.iti input[type='text'],
.iti input[type='tel'] {
	z-index: 10;
}

.iti__country-list::-webkit-scrollbar {
	width: 8px;
}

.iti__country-list::-webkit-scrollbar-track {
	background: #f4f1f1;
}

.iti__country-list::-webkit-scrollbar-thumb {
	background: $yellow-500;
}

// .tel-prefix {
// 	display: none;
// }
