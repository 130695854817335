.image-lazy {
	display: inline-block;
	transition: all 0.3s ease;
	background-color: #cfe6ff;
	will-change: filter, transform;

	&:not(.is-loaded) {
		filter: blur(10px);
		transform: scale(1.05);
	}
}

.lazy-wrap {
	background-color: #cfe6ff;
}
