@import "./image-lazy/image-lazy";
@import "./select/select";
@import "./node_modules/intl-tel-input/build/css/intlTelInput";
@import "./node_modules/toastify-js/src/toastify";
@import "./node_modules/swiper/swiper.scss";

img {
  max-width: 100%;
  height: auto;
}

.toastify {
  background: $blue-900 !important;
  padding: 20px 24px;
  color: $white;
  border-radius: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: none;
  max-width: 100%;
  left: 20px;
  right: 20px;
  max-width: 1140px;

  @include media-breakpoint-down(xl) {
    max-width: 888px;
  }
  @include media-breakpoint-down(lg) {
    max-width: 660px;
  }
  @include media-breakpoint-down(md) {
    max-width: 540px;
  }
  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}

.toast-close {
  font-size: 0;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDRMNC4wMDAwMSAxNiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTQgNEwxNiAxNiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==");
  height: 20px;
  width: 20px;
  background-size: cover;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  background-repeat: no-repeat;

  &:hover {
    opacity: 0.6;
  }
}
