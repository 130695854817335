.invest {
	padding: 171px 0 138px;
	position: relative;

	@include media-breakpoint-down(xl) {
		padding: 138px 0 106px;
	}

	@include media-breakpoint-down(lg) {
		padding: 130px 0 114px;
	}

	&__video {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 100%;
		width: 100%;
		transform: translate(-50%, -50%);
		display: flex;
		justify-content: center;
		object-fit: cover;
		align-items: center;
	}

	&__inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		max-width: 640px;
		margin: auto;

		@include media-breakpoint-down(lg) {
			max-width: 582px;
		}
	}

	&__title {
		margin-bottom: 16px;
		font-size: 48px;
		font-weight: 600;
		line-height: 48px;
		letter-spacing: -0.72px;
		color: $white;
		text-align: center;

		@include media-breakpoint-down(lg) {
			font-size: 32px;
			font-weight: 500;
			line-height: 40px;
			letter-spacing: -0.32px;
			margin-bottom: 24px;
		}
	}

	&__desc {
		text-align: center;
		font-size: 14px;
		line-height: 22px;
		color: rgba($color: white, $alpha: 0.6);
		font-weight: 500;

		a{
			font-size: 14px;
			line-height: 22px;
			font-weight: 500;
			color: $yellow-500;
			position: relative;

			&:hover {
				color: $yellow-550;
				&:after {
					opacity: 0;
				}
			}

			&:after {
				content: '';
				position: absolute;
				bottom: 2px;
				height: 1px;
				width: 100%;
				left: 0;
				background-color: $yellow-500;
				transition: opacity 0.3s ease;
			}
		}
	}

	&__button {
		margin-bottom: 16px;
		min-width: 218px;
		@include media-breakpoint-down(lg) {
			margin-bottom: 12px;
			font-size: 14px;
			padding: 16px 32px;
		}
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}

	
}
