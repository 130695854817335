.language-dropdown {
	position: relative;
	&-btn {
		color: $grey-100;
		display: flex;
		align-items: center;
		transition: color 0.3s ease-in-out;

		.dark & {
			color: $grey-500;
		}

		&:hover {
			color: $yellow-500;
		}

		&__icon {
			font-size: 24px;

			&.icon-shevron-down-light {
				transition: transform 0.3s ease-in-out;
			}
		}

		&.show {
			color: $yellow-500;

			.icon-shevron-down-light {
				transform: rotateX(-180deg);
			}
		}
	}

	&-item {
		display: flex;
		align-items: center;
		color: $blue-900;
		border-radius: 12px;
		overflow: hidden;

		&:not(.active) {
			.language-dropdown-item__check {
				display: none;
			}
			.language-dropdown-item__val {
				font-weight: 400;
			}
		}

		&__img {
			margin-right: 8px;
		}

		&__val {
			font-weight: 500;
			line-height: 24px;
		}

		&__check {
			margin-left: auto;
			font-size: 20px;
		}

		&__link {
			display: flex;
			align-items: center;
		}
	}

	.dropdown-menu {
		position: absolute;
		right: 0;
		z-index: 1000;

		@include media-breakpoint-down(md) {
			top: -110px;
		}
	}
}
