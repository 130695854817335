.account-sidebar {
	width: 267px;
	height: 266px;
	margin-top: 72px;
	.button {
		justify-content: flex-start;
	}
	.button.active {
		color: $blue-500;
	}
	@include media-breakpoint-down(md) {
		margin-top: 0px;
		height: 30px;
		width: 100%;
	}

	&__list {
		@include media-breakpoint-down(md) {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 26px;
		}
	}
	&__inner {
		gap: 16px;
		display: flex;
		flex-direction: column;
	}
	&__info {
		display: flex;
		gap: 12px;
		margin-top: 24px;
		@include media-breakpoint-down(md) {
			display: none;
		}
		&--initials {
			display: flex;
			flex-direction: column;
			justify-content: center;
			p {
				margin: 0;
			}
		}
		&--name {
			font-size: 16px;
			color: $blue-900;
		}
		&--icon {
			width: 56px;
			height: 56px;
			overflow: hidden;
			border-radius: 50%;
			flex-shrink: 0;
			border: 1px solid rgba(0, 128, 255, 0.2);
			img {
				height: 100%;
				width: auto;
			}
		}
	}
	&__tab {
		white-space: nowrap;
		@include media-breakpoint-down(md) {
			a {
				background: none;
				padding: 0;
			}
		}
		&.item-right {
			@include media-breakpoint-down(md) {
				margin-left: auto;
			}
		}
		button {
			width: 100%;
			justify-content: flex-start;
			@include media-breakpoint-down(md) {
				min-width: 170px;
				justify-content: center;
			}
			&.active {
				color: $blue-500;
			}
		}
	}
}
