.project-subscription-dialog {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-top: 3px solid $yellow-500;
  align-self: stretch;
  background: $white;
  box-shadow: 0px 2px 20px 0px rgba(0, 77, 229, 0.12);
  height: 486px;

  &__form {
    position: relative;
    max-width: 558px;
    margin: auto;
    margin-top: auto;

    @include media-breakpoint-down(lg) {
      max-width: 410px;
		}

		@include media-breakpoint-down(sm) {
			max-width: 270px;
		}
  }

  &__checks {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
  }

  &__checks-svg {
    margin-top: 5px;
    margin-right: 10px;
  }

  &__price{
    font-family: Geologica;
    font-size: 20px;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: -0.005em;
    text-align: center;
    margin-bottom: 20px;
  }
  
  &__price-text {
    background-color: #FAD4194D; 
    border-radius: 2px;
    padding-left: 4px;
    padding-right: 4px;
    white-space: nowrap;
  }

  &__subtitle {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.01em;
    text-align: center;
  }


  &__paragraph {
    align-items: center;
    text-align: center;
    margin: 0;
    margin-top: 5px;
    
  }

  &__link {
    margin-left: 5px; 
  }

  &__coa {
    position: absolute;
    top: 0;
    left: -6%;
    display: flex;
    align-items: center;
    font-size: 40px;
    color: #ffd500;
  }

  &__button {
		font-size: 14px;
    width: 366px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;

		@include media-breakpoint-down(lg) {
			padding: 14px 20px;
			line-height: 22px;
		}

		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}
}