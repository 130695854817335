.button {
	background-color: $yellow-500;
	border-radius: 100px;
	color: $blue-900;
	cursor:pointer;
	font-weight: 500;
	line-height: 24px;
	transition: all 0.3s ease;
	padding: 20px 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;

	&:hover {
		background-color: $yellow-550;
		color: $blue-900;

		.icon {
			transform: translateX(4px);
		}
	}

	&--bg-transparent {
		background: transparent;

		&:hover {
			background: rgba(0, 128, 255, 0.08);
		}
	}

	&--bg-gray {
		background: rgba(0, 128, 255, 0.08);

		&:hover {
			background: rgba(0, 128, 255, 0.12);
		}
	}

	&--size-l {
		padding: 16px 24px;
	}

	&--size-m {
		padding: 12px 20px;
		font-size: 14px;
		line-height: 22px;
	}

	&--size-s {
		padding: 12px 20px;
		font-size: 14px;
		line-height: 22px;
		height: 36px;
	}

	.icon {
		font-size: 24px;
		transition: transform 0.3s ease;
		will-change: transform;
	}

	&.startups_button{
		background-color: #002266;
	    color: #fff;
	}
}
button{
	cursor:pointer;
}





















































