.account-subscription-page {
	padding: 0 329px 255px;

	@include media-breakpoint-down(xl) {
		padding: 0 150px 150px;
	}

	@include media-breakpoint-down(lg) {
		padding-left: 20px;
		padding-right: 20px;
	}

	&:before {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 1440px;
		height: 683px;
		z-index: -1;
		background: $blue-100;
		background: linear-gradient(180deg, #e6f2ff 0%, rgba(230, 242, 255, 0) 100%),
			url('../../images/lead-image.webp');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	&__title {
		margin-top: 32px;
		margin-bottom: 30px;
	}

	/*&__svg-wrapper {
		display: flex;
	}*/

	&__description {
		font-size: 20px;
		line-height: 1.5;
		color: $blue-900;
		padding-bottom: 16px;
		border-bottom: 1px solid #0080ff33;
		margin: 0;
	}

	&__payment {
		display: flex;
		justify-content: space-between;
		color: $blue-900;
		padding-bottom: 16px;
		border-bottom: 1px solid #0080ff33;
		padding: 24px 0;

		&:last-child {
			margin-bottom: 48px;
		}
	}

	&__payment-container {
		display: flex;
	}

	&__payment-radio {
		display: none;
	}

	&__payment-radio-fake {
		display: inline-block;
		cursor: pointer;
		position: relative;
		width: 20px;
		height: 20px;
		margin-right: 12px;
		border-radius: 50%;
		margin-top: 2px;
		border: 1px solid var(--grey-200, #cad1e1);
		background-color: $white;

		&:hover {
			border: 1px solid $yellow;
		}
	}

	&__payment-radio:checked ~ &__payment-radio-fake:before {
		content: '';
		display: inline-block;
		position: absolute;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		border: 6px solid $yellow;
	}

	&__payment-radio:checked ~ &__payment-radio-fake {
		border: none;
	}

	&__icon-visa {
		margin-left: 12px;
	}

	&__total-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #0080ff33;
	}

	&__total {
		margin-top: 22px;
		margin-bottom: 18px;
	}

	&__total-value {
		font-weight: 500;
		font-size: 24px;
		line-height: 1.3;
		color: $blue-900;
		margin: 0;
	}

	&__terms {
		margin-top: 16px;
		margin-bottom: 4px;
		color: $blue-900;
	}

	&__terms-description {
		margin-bottom: 16px;
	}

	&__checkbox {
		background-color: inherit;

    // TODO: checkbox review
		.form-control__text {
			position: relative;
			cursor: pointer;
			display: flex;
			font-size: 16px;
			line-height: 1.5;
			margin-bottom: 64px;
		}
		.form-control__text:before {
			content: '';
			position: relative;
			cursor: pointer;
			margin-right: 12px;
			border-radius: 6px;
			border: 1.5px solid $grey-200;
			height: 20px;
			width: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			background-color: $white;
		}

		.form-control__input {
			display: none;
		}

		.form-control__input:checked ~ .form-control__text:after {
			content: '';
			display: block;
			position: absolute;
			top: 4px;
			left: 7px;
			width: 6px;
			height: 10px;
			border: solid $blue-900;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
		}
	}

	&__button-container {
		display: flex;
		justify-content: flex-end;

		@include media-breakpoint-down(lg) {
			justify-content: center;
		}
		.button, form{
			width:100%;
		}
	}
}
#sub-register .account-subscription-page__button-container .button{
	width: auto;
}
.payment_box{
	display: none;
}
.woocommerce-error, .woocommerce-info, .woocommerce-message{
	background-color:transparent !important;
}
.woocommerce .button{
	background-color: var(--accent-color) !important;
    border-radius: 100px !important;
    color: #002266 !important;
    cursor: pointer !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    transition: all 0.3s ease !important;
    padding: 20px 32px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 4px !important;
}
.woocommerce .button:hover{
	background-color: var(--accent-color-hover) !important;
    color: #002266 !important;
}
.wc_payment_method{
	   justify-content: space-between;
    color: #002266;
    padding-bottom: 16px;
    border-bottom: 1px solid #0080ff33;
    padding: 24px 0;
}
.wc_payment_method label{
    margin-left: 12px;
    margin-top: 2px;
}