.tooltip-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.tooltip-arrow {
	display: none !important;
}

.tooltip-inner {
	border-radius: 8px;
	background-color: $blue-900;
	font-size: 14px;
	text-align: center;
	color: $gray-100;
	line-height: 18px;
	padding: 6px 12px;

	a {
		color: $yellow-500;
	}
}
