.inquiry {
	&__form {
		gap: 10px;
		display: flex;
		flex-direction: column;
	}
	&__row {
		display: flex;
		justify-content: space-between;
		column-gap: 24px;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
	}
	&__col {
		width: calc(50% - 12px);

		@include media-breakpoint-down(sm) {
			width: 100%;
		}

		&.full {
			width: 100%;
		}
	}
	&__btn {
		display: flex;
		justify-content: flex-end;
		margin-top: 6px;

		.button {
			min-width: 166px;

			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}
	}
}
