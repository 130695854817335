@import './node_modules/tom-select/dist/scss/tom-select.scss';

.ts-wrapper.full {
	.ts-control {
		background: rgba(0, 128, 255, 0.08);
		padding: 5px 12px;
		border: none;
		border-radius: 32px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer !important;
		z-index: 0;

		& > * {
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			color: $grey-500;
		}

		input {
			display: none !important;
		}

		&:after {
			content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMgNkw3Ljg1ODU4IDEwLjg1ODZDNy45MzY2OCAxMC45MzY3IDguMDYzMzIgMTAuOTM2NyA4LjE0MTQyIDEwLjg1ODZMMTMgNiIgc3Ryb2tlPSIjNkI4M0IzIiBzdHJva2Utd2lkdGg9IjEuMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=);
			display: block;
			width: 16px;
			height: 16px;
			margin-right: 12px;
			transition: transform 0.3s ease-in-out;
			margin-left: 2px;
		}
	}
}

.ts-wrapper.single.input-active .ts-control {
	background: rgba(0, 128, 255, 0.1);
}
.ts-wrapper.dropdown-active .ts-control {
	&:after {
		transform: rotateX(180deg);
	}
}

.ts-dropdown {
	background-color: #fff;
	box-shadow: 0px 8px 48px 0px rgba(0, 0, 102, 0.1);
	border: none;
	box-shadow: none;
	overflow: hidden;
	z-index: 2;
	border: none;
	border-radius: 12px;
	padding: 4px;
	width: 168px;
	right: 0;
	left: auto;

	.active {
		background-color: #fff;
	}
}

.ts-dropdown [data-selectable].option {
	color: $blue-900;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	border-radius: 8px;
	padding: 12px 16px;
	transition: all 0.3s ease-in-out;

	&:hover {
		background: $grey-50;
	}
}
