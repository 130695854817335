.dropdown {
	&-menu {
		border: none;
		box-shadow: 0px 8px 48px 0px rgba(0, 0, 102, 0.1);
		border-radius: 12px;
		overflow: hidden;
		background-color: $white;
		padding: 4px;
	}

	&-item {
		padding: 10px 16px;
		width: 160px;
		transition: background-color 0.3s ease-in-out;

		&:hover,
		&:focus,
		&:active {
			background-color: $blue-100;
			color: inherit;
		}
	}

	&-toggle {
		&:after {
			display: none;
		}
	}
}
