.region-page {
	padding: 0 0 96px;
	position: relative;

	@include media-breakpoint-down(xl) {
		padding: 0 0 65px;
	}

	&__top {
		display: flex;
		flex-direction: column;
		gap: 23px;
		margin-bottom: 24px;

		@include media-breakpoint-down(md) {
			gap: 15px;
			margin: 0;
		}

		&-line {
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: space-between;
			margin-top: 32px;
		}
	}

	&__head {
		display: flex;
		position: relative;
	}

	&__back {
		font-size: 64px;
		color: $grey-500;
		transition: color 0.2s ease-in-out;
		display: flex;
		align-items: center;
		position: absolute;
		right: calc(100% + 21px);
		top: 10px;

		@include media-breakpoint-down(xl) {
			display: none;
		}

		&:hover {
			color: $blue-900;
		}
	}

	&__main {
		display: flex;
		justify-content: space-between;
		gap: 24px;
		margin-top: 26px;
		position: relative;

		@include media-breakpoint-down(md) {
			margin-top: 10px;
		}
	}

	&__svg {
		position: relative;
		pointer-events: none;

		&-inner {
			position: absolute;
			top: 80px;
			right: 16px;
		}
	}

	&__left {
		max-width: 728px;
		width: 100%;

		@include media-breakpoint-down(xl) {
			max-width: 584px;
			padding-right: 24px;
		}

		@include media-breakpoint-down(lg) {
			max-width: 100%;
			padding-right: 0;
		}
	}

	&__info {
		padding-right: 24px;
		margin-top: 32px;

		@include media-breakpoint-down(xl) {
			padding-right: 0;
			margin-top: 24px;
		}

		@include media-breakpoint-down(lg) {
			margin-top: 0;
		}

		&-wrap {
			@include media-breakpoint-down(lg) {
				display: flex;
				justify-content: space-between;
				gap: 36px;
			}

			@include media-breakpoint-down(md) {
				gap: 18px;
			}

			@include media-breakpoint-down(md) {
				flex-direction: column;
			}
			.region-page__info,
			.region-page__info--mobile {
				@include media-breakpoint-down(lg) {
					max-width: 306px;
					width: 100%;
				}
				@include media-breakpoint-down(md) {
					max-width: 100%;
				}
			}
		}
	}

	&__info--mobile {
		display: none;

		@include media-breakpoint-down(lg) {
			display: block;
		}
	}

	&__sidebar {
		width: 364px;
		flex-shrink: 0;
		margin-top: 6px;

		@include media-breakpoint-down(xl) {
			width: 272px;
		}

		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	&__title {
		font-size: 73px;
		font-weight: 600;
		line-height: 80px;
		margin-bottom: 24px;
		letter-spacing: -1.1px;

		@include media-breakpoint-down(xl) {
			font-size: 48px;
			font-weight: 600;
			line-height: 48px;
			letter-spacing: -0.72px;
			max-width: 475px;
		}

		@include media-breakpoint-down(lg) {
			max-width: 100%;
		}

		@include media-breakpoint-down(md) {
			font-size: 32px;
			font-weight: 500;
			line-height: 40px;
			letter-spacing: -0.32px;
		}
	}

	&__subtitle {
		font-size: 24px;
		font-weight: 300;
		line-height: 32px;
		letter-spacing: -0.24px;
		margin-bottom: 30px;
		color: $blue-900;

		@include media-breakpoint-down(lg) {
			margin-bottom: 31px;
		}

		@include media-breakpoint-down(md) {
			font-size: 20px;
			line-height: 26px;
			letter-spacing: -0.1px;
		}
	}

	&__img {
		position: relative;

		&:after {
			content: '';
			display: block;
			padding-bottom: 35.5%;

			@include media-breakpoint-down(md) {
				padding-bottom: 74.5%;
			}
		}

		picture {
			height: 100%;
			width: 100%;
			min-height: 100%;
			position: absolute;
		}

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
			min-height: 100%;
		}
	}

	&__desc {
		display: flex;
		align-items: stretch;
		flex-direction: column;
		margin-top: 32px;

		@include media-breakpoint-down(xl) {
			max-width: 536px;
		}

		@include media-breakpoint-down(lg) {
			max-width: 100%;
			margin-top: 16px;
		}

		@include media-breakpoint-down(md) {
			padding-right: 0;
			margin-top: 32px;
		}

		&-inner {
			max-height: 185px;
			overflow: hidden;
			position: relative;
			transition: max-height 0.2s ease-in-out;

			&:after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 64px;
				opacity: 1;
				background: linear-gradient(180deg, rgba(230, 242, 255, 0) 0%, rgba(230, 242, 255, 0.7) 100%);
			}

			&.active {
				&::after {
					opacity: 0;
				}
			}
		}
		&-btn {
			display: flex;
			align-items: center;
			color: $blue-500;
			margin-top: 8px;

			&.active .icon {
				transform: rotate(180deg);
			}
			.icon {
				font-size: 24px;
			}
		}
	}

	&__money {
		margin-bottom: 32px;

		@include media-breakpoint-down(lg) {
			display: none;
		}
		&-val {
			font-size: 40px;
			font-weight: 600;
			line-height: 48px;
			letter-spacing: -0.72px;
			margin-bottom: 4px;
			display: flex;

			.animated-count {
				text-overflow: ellipsis;
				overflow: hidden;
			}

			@include media-breakpoint-down(lg) {
				font-size: 32px;
				font-weight: 500;
				line-height: 40px;
				letter-spacing: -0.32px;
				margin-bottom: 0;
			}
		}

		&--mobile {
			display: none;
			margin-bottom: 24px;
			margin-top: 48px;

			@include media-breakpoint-down(lg) {
				display: flex;
			}

			@include media-breakpoint-down(md) {
				margin-top: 33px;
			}

			.region-page__coa-icon {
				width: 64px;
				margin-right: 16px;
			}
		}
	}
	&__stats {
		display: flex;
		gap: 32px;
		margin-bottom: 14px;

		@include media-breakpoint-down(xl) {
			flex-direction: column;
		}

		@include media-breakpoint-down(lg) {
			margin-bottom: 15px;
			gap: 0;
		}

		&--info {
			flex-direction: column;
			gap: 0;

			.region-page__stat-val {
				max-width: none;
			}
			.region-page__stat-txt {
				max-width: 150px;
			}
		}

		&-left {
			flex-shrink: 0;

			.region-page__coa-icon {
				width: 64px;
			}
		}

		&-right {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
		}
	}

	&__stat {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding: 16px 0;
		position: relative;

		@include media-breakpoint-down(lg) {
			gap: 20px;
		}

		&:not(:last-child),
		&.isEmpty,
		&:first-child {
			border-bottom: 1px solid rgba($blue-500, 0.2);
		}

		&.isEmpty {
			justify-content: center;

			.region-page__stat-txt-empty {
				@include media-breakpoint-down(lg) {
					max-width: 306px;
				}
			}
		}

		&-txt {
			max-width: 110px;
			width: 100%;
			white-space: nowrap;
			display: flex;
			align-items: center;
			flex-shrink: 0;
			text-overflow: ellipsis;
			overflow: hidden;

			&-empty {
				max-width: 100%;
			}
		}

		&-val {
			color: $blue-900;
			max-width: 144px;
			display: -webkit-box;
			
			-webkit-line-clamp: 3;
			overflow: hidden;
			text-align: right;
		}

		.icon {
			font-size: 24px;
			&-flag {
				color: $green;
			}
		}

		&-link {
			color: $blue-500;
			display: flex;
			align-items: center;
			.icon {
				font-size: 24px;
			}
		}
	}

	&__connection {
		display: flex;
		align-items: center;
		color: $blue-500;

		@include media-breakpoint-down(lg) {
			justify-content: flex-end;
		}

		.icon {
			font-size: 24px;
		}
	}

	&__btn {
		display: flex;
		justify-content: center;
		flex-grow: 1;

		@include media-breakpoint-down(lg) {
			display: none;
		}

		button {
			width: 100%;
		}

		&-project {
			&-wrap {
				display: flex;
			}
		}
	}

	&__lists {
		gap: 86px;
		margin-top: 67px;
		display: flex;
		flex-direction: column;

		@include media-breakpoint-down(xl) {
			margin-top: 25px;
			gap: 55px;
		}

		@include media-breakpoint-down(lg) {
			margin-top: 16px;
			gap: 20px;
		}
		@include media-breakpoint-down(md) {
			gap: 44px;
			margin-top: 42px;
		}
	}

	.list {
		&__head {
			@include media-breakpoint-down(lg) {
				margin-bottom: 40px;
			}

			@include media-breakpoint-down(md) {
				margin-bottom: 22px;
			}
		}
		&-item {
			@include media-breakpoint-down(lg) {
				padding: 26.5px 12px;
			}
			@include media-breakpoint-down(md) {
				padding: 29px 3px 21px;
			}
		}

		&__load-more {
			@include media-breakpoint-down(lg) {
				margin-top: 46px;
			}
			@include media-breakpoint-down(md) {
				margin-top: 24px;
			}
		}
	}

	&__footer {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		box-shadow: 0px 16px 48px 0px rgba(0, 77, 229, 0.16);
		background: $white;
		z-index: 2;
		display: none;
		padding: 12px 0;

		&-inner {
			justify-content: space-between;
			align-items: center;
			display: flex;
		}

		.button {
			@include media-breakpoint-down(md) {
				font-size: 14px;
				padding: 16px 24px;
				.icon {
					display: none;
				}
			}
			@include media-breakpoint-down(sm) {
				padding: 12px 20px;
			}
		}

		.region-page__money-val {
			font-size: 24px;
			font-weight: 500;
			line-height: 32px;

			@include media-breakpoint-down(md) {
				font-size: 20px;
				line-height: 26px;
				letter-spacing: -0.1px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 16px;
				line-height: 24px;
			}
		}

		.region-page__money-txt {
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			display: inline-block;

			@include media-breakpoint-down(md) {
				line-height: 18px;
			}

			@include media-breakpoint-down(sm) {
				max-width: 145px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}

		@include media-breakpoint-down(lg) {
			display: block;
		}
	}

	&__rebuild {
		.rebuild {
			padding: 58px 0 98px;

			@include media-breakpoint-down(xl) {
				padding: 87px 0 98px;
			}

			@include media-breakpoint-down(lg) {
				padding: 0 0 4px;
			}
		}
	}
}
