.text-page {
	padding: 44px 0 68px;

	.container {
		max-width: 889px;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 16px;
		line-height: 1;

		@include media-breakpoint-down(sm) {
			margin-bottom: 17px;
		}
	}

	h1 {
		@include media-breakpoint-down(xl) {
			font-size: 48px;
			line-height: 1;
		}

		@include media-breakpoint-down(sm) {
			font-size: 32px;
			line-height: 1.25;
		}
	}

	h2,
	h3 {
		line-height: 1.2;
	}

	ul,
	ol {
		margin-bottom: 29px;
		list-style: auto;
   		padding-left: 18px;
	}

	p,
	li {
		line-height: 1.5;
		letter-spacing: 0.1px;
	}
	p {
		margin-bottom: 24px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 27px;
		}
	}
	li {
		margin-bottom: 16px;
	}
}
