.error {
	flex-direction: column;
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	color:#fa5332;
	&__inner {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	&__title {
		font-size: 24px;
		font-weight: 500;
		line-height: 32px;
		margin-bottom: 12px;
	}

	&__desc {
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 32px;
	}
	&__btn{
	    display: flex;
	    .button{
	    	margin: 0 1rem;
	    }
	}
}
